import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { AddIcons, CheckoutColorIcons } from '../../assets/icons/IconsSvg';
import Fileal from '../../assets/images/Fililal logo.png';
import Fileal2 from '../../assets/images/Fililal logo2.png';
import { COLORS } from '../../constants/colors';
import useRootStore from '../../hooks/useRootStore';
import styles from './Sidebar.module.css';

const data = [
	{
		id: 0,
		title: 'Islomiy hadiy...',
		imag_url: Fileal,
	},
	{
		id: 1,
		title: '100K Express',
		imag_url: Fileal2,
	},
	{
		id: 2,
		title: 'Bon-Ton store',
		imag_url: Fileal,
	},
];
type propsType = {
	setShow(arg0: (e: any) => boolean): void;
	addComponents?: any;
};

const SidebarView = observer((props: propsType) => {
	const [state, setState] = useState('');
	const store = useRootStore();
	const sidebarItemList = store.allUser.allUsersStorageDataList;

	return (
		<div
			style={{ left: props.addComponents ? '300px' : '-300px' }}
			className={styles.sidebar_box}>
			<h3 className={styles.sidebar_title}>Filiallar</h3>
			<div className={styles.list_box}>
				{sidebarItemList.map((item) => {
					return (
						<div
							key={item.id}
							style={{
								background:
									state === item.name ? COLORS.wh100 : '',
							}}
							className={styles.list}
							onClick={() => setState(item.name)}>
							<div className={styles.img_box}>
								<img src={item.logo} alt='' />
							</div>
							<div className={styles.title_box}>
								<p>{item.name}</p>
							</div>
							<div
								className={styles.checkout_box}
								style={{ background: COLORS.bl200 }}>
								{state === item.name ? (
									<CheckoutColorIcons />
								) : null}
							</div>
						</div>
					);
				})}
			</div>
			<div
				className={styles.add_item_box}
				style={{ background: COLORS.wh100 }}>
				<div
					style={{
						display: 'flex',
						width: '203px',
						cursor: 'pointer',
					}}
					onClick={() => props.setShow((e) => !e)}>
					<div className={styles.add_icon}>
						<AddIcons color={COLORS.bl1000} />
					</div>
					<p>Yangi filial qo‘shish</p>
				</div>
			</div>
		</div>
	);
});

export default SidebarView;
