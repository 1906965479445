import React, { useState } from "react";
import styles from "./ExpressView.module.css";
import ExpressNavbar from "../../../../components/Navbar/ExpressNavbar/ExpressNavbar";
import Settings from "./components/Settings/Settings";
import Transactions from "./components/Transactions/Transactions";
import Notifications from "./components/Notifications/Notifications";
import Api100K from "./components/Api100K/Api100K";
const data = [
  {
    id: 0,
    title: "Sozlamalar",
  },
  {
    id: 1,
    title: "Tranzaksiyalar",
  },
  {
    id: 2,
    title: "Bildirishnomalar",
  },
  {
    id: 3,
    title: "API",
  },
];

interface Props {
  onOpen?: boolean;
  onCancel?: () => void;
  show?: any;
}

const ExpressView = () => {
  const [active, setActive] = useState();

  let saidbar = "Sozlamalar";

  if (active === "Sozlamalar") {
    saidbar = "Sozlamalar";
  } else if (active === "Tranzaksiyalar") {
    saidbar = "Tranzaksiyalar";
  } else if (active === "Bildirishnomalar") {
    saidbar = "Bildirishnomalar";
  } else if (active === "API") {
    saidbar = "API";
  }
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <ExpressNavbar title="100K Express" data={data} setActive={setActive} />
      </div>
      {saidbar === "Sozlamalar" ? <Settings /> : null}
      {saidbar === "Tranzaksiyalar" ? <Transactions /> : null}
      {saidbar === "Bildirishnomalar" ? <Notifications /> : null}
      {saidbar === "API" ? <Api100K /> : null}
    </div>
  );
};

export default ExpressView;
