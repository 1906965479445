import React, { CSSProperties, useState } from "react";
import { ChromeIcons, DataIcons } from "../../assets/icons/IconsSvg";

import ItemCartSort from "../ItemCart/ItemCartSort";
import SearchView from "../SearchView/SearchView";
import ListModalView from "../SortModals/ListModal/ListModalView";
import styles from "./SortView.module.css";

type PropsType = {
  data?: any;
  ListModaldata?: any;
  title?: string;
  cartSortStyle?: CSSProperties;
};
const SortView = (props: PropsType) => {
  const [onClick, setOnClick] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.search}>
        <SearchView setOnClick={setOnClick} sort={true} />
        <ListModalView
          ListModaldata={props.ListModaldata}
          onClick={onClick}
          title={props.title}
        />
      </div>
      <div className={styles.data}>
        {props.data?.map((item: { icon: any; info: any }, index: number) => {
          return (
            <ItemCartSort
              key={index}
              icon={item.icon}
              info={item.info}
              cartSortStyle={props.cartSortStyle}
            />
          );
        })}
      </div>
    </div>
  );
};

export default SortView;
