import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddIcons,
  CheckoutIcons,
  CloseIcons,
  DotIcons,
  HeshtecIcons,
  LockIcons,
  PrintIcon,
  RealLock,
} from "../../../assets/icons/IconsSvg";
import { COLORS } from "../../../constants/colors";
import ButtonView from "../../ButtonView/view";
import ListItem from "../../ListItem/ListItem";
import styles from "./style.module.css";
type PropsType = {
  data?: any;
  title?: string;
  info?: any;
  button_title?: any;
  typeMain?: any;
  setActive?: any;
  active?: any;
  newbtn?: any;
  id?: any;
};

const EnterInNavbar = (props: PropsType) => {
  const [clickMe, setClickMe] = useState("Pochtalar");

  const onClivj = ({ title }: { title: string }) => {
    setClickMe(title);
    props?.setActive(title);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.left_container}>
        <div className={styles.title_box}>
          <h1>{props.title}</h1>
          <div className={`${styles.canceled} ${styles.btn}`}>
            <p>#{props.id}</p>
          </div>
          {props.active ? (
            <div className={`${styles.active} ${styles.btn}`}>
              <DotIcons color=" #F5C519" />
              <p>Aktiv</p>
            </div>
          ) : null}

          {props.newbtn ? (
            <div className={`${styles.new} ${styles.btn}`}>
              <DotIcons color="#1CCA18" />
              <p>Yangi</p>
            </div>
          ) : null}
        </div>
        {props.data ? (
          <div className={styles.list_box_div}>
            <ul className={styles.list_box}>
              {props.data?.map((item: any, index: number) => {
                return (
                  <ListItem
                    key={index}
                    title={item.title}
                    setClickMe={onClivj}
                    clickMe={clickMe}
                    color={item.color}
                  />
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
      <div className={styles.right_container}>
        <ButtonView
          buttonSize={"large"}
          buttonType={"primary"}
          style={{
            border: "none",
            background: COLORS.sc700,
            fontSize: "16px",
          }}
          title="Printerdan chiqarish"
          icon={<PrintIcon />}
        />
        <ButtonView
          buttonSize={"small"}
          buttonType={"primary"}
          style={{
            background: COLORS.wh1000,
            border: "1px solid #EE1E50",
            color: "red",
            marginLeft: "20px",
          }}
          title="Ro‘yhatni yopish"
          icon={<RealLock />}
        />
      </div>
    </div>
  );
};

export default EnterInNavbar;
