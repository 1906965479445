import FilterList from "../../../../../../components/FilterList.tsx/FilterList";
import styles from "./Transactions.module.css";
import TransactionsList from "./TransactionsList/TransactionsList";
const data = [
  { id: 0, title: "ID", flex: 0.5 },
  { id: 1, title: "Holat", flex: 1 },
  { id: 2, title: "Summasi", flex: 0.7 },
  { id: 2, title: "Vaqt", flex: 0.7 },
  { id: 2, title: "Izoh", flex: 1 },
];
const Transactions = () => {
  return (
    <div className={styles.lists_filter}>
      <div className={styles.filter}>
        <FilterList data={data} />
      </div>
      <div className={styles.lists}>
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
        <TransactionsList />
      </div>
    </div>
  );
};

export default Transactions;
