import React from 'react'
import { Dropdown, Space, MenuProps } from 'antd';
import styles from "./style.module.css"
import { CSSProperties } from 'styled-components';
import { InternetIcons } from '../../assets/icons/IconsSvg';
import { useState } from 'react';
interface Props {
    clickMe?: () => void;
    style?: CSSProperties,
    space?: CSSProperties,
}

const DropdownTr: React.FC<Props> = ({
    clickMe,
    style,
    space
}) => {

    const [dropDownValue, setDropDownValue] = useState("")
    const [openDropDown, setOpenDropDown] = useState(false)

    const onClick: MenuProps['onClick'] = ({ key }: any) => {

        setDropDownValue(key)
        setOpenDropDown(false)
    };

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <span style={{ marginLeft: "5px" }}>
                    O‘zb
                </span>
            ),
            icon: <InternetIcons />,
        },
        {
            key: '2',
            label: (
                <span style={{ marginLeft: "5px" }}>
                    Ўзб
                </span>
            ),
            icon: <InternetIcons />,
        },
        {
            key: '3',
            label: (
                <span style={{ marginLeft: "5px" }}>
                    Рус
                </span>
            ),
            icon: <InternetIcons />,
        },
    ];

    return (
        <div className={styles.content} style={{ ...style }}>
            <Dropdown
                menu={{ items, onClick }}
                open={openDropDown}
                onOpenChange={(open) => setOpenDropDown(open)}
                overlayStyle={{
                    width: "100px",
                    backgroundColor: "#FFF6F0",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.25)",
                    border: "1px solid #E5E5E5",
                    zIndex: 1000,
                    marginTop: "10px",
                    marginBottom: "10px",
                }}
                trigger={["click"]}
                placement="bottom"
            >
                <Space
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        ...space,
                    }}>
                    <InternetIcons />
                    <span className={styles.item}>O'zb</span>
                </Space>
            </Dropdown>
        </div >
    );
}

export default DropdownTr
