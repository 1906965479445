import { useEffect, useState } from "react";
import { SidebarData } from "./dashboardData";
import "./style.css";

import { NavLink } from "react-router-dom";
import {
  LogoIcons,
  MenyuIcons,
  MenyuRightIcons,
  MoreMenyuIcons,
} from "../../assets/icons/IconsSvg";
import FilialImage from "../../assets/images/Filial image.png";
import AvatarIMage from "../../assets/images/User image.png";
import { COLORS } from "../../constants/colors";
import PersonItem from "../PersonItem/PersonItem";
type propsType = {
  setAddcomponents?: any;
  setLogOut?: any;
};

function Dashboard(props: propsType) {
  const [colorActive, setColorActive] = useState("");

  return (
    <>
      <nav className={"navbar-menu active"}>
        <div className="Logo">
          <div className="logo_container">
            <div className="logo_icon">
              <LogoIcons />
            </div>
            <div className="menyu_icon">
              <MenyuRightIcons />
            </div>
          </div>
        </div>
        <div className="exspres">
          <div className="exspres_image">
            <div className="image_div">
              <img src={FilialImage} alt="" />
            </div>
            <NavLink to={"/100k"} className="nav-text-100k">
              <span>100K Express</span>
            </NavLink>
          </div>
          <div
            style={{ cursor: "pointer" }}
            className="exspres_icon"
            onClick={() => props.setAddcomponents((a: any) => !a)}
          >
            <MenyuIcons />
          </div>
        </div>
        <ul className="navbar-menu-items">
          {SidebarData.map((item, index) => {
            return (
              <NavLink
                to={item.path}
                key={index}
                onClick={() => setColorActive(item.title)}
                className={
                  colorActive === item.title
                    ? "nav-text activeColor"
                    : "nav-text"
                }
              >
                <div className="item_style">{item.icon}</div>
                <span className="span">{item.title}</span>
              </NavLink>
            );
          })}
        </ul>
        <div className="personInfo">
          <PersonItem
            img={AvatarIMage}
            ItemStyle={{ backgroundColor: COLORS.bl600, paddingLeft: "25px" }}
            title="Abdulaziz"
            info="Admin"
            icon={<MoreMenyuIcons />}
            IconStyle={{
              backgroundColor: COLORS.bl700,
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
            TitleStyle={{ fontSize: "19px", color: COLORS.wh1000 }}
            InfoStyle={{ fontSize: "16px", color: COLORS.ss400 }}
            setLogOut={props.setLogOut}
          />
        </div>
      </nav>
    </>
  );
}

export default Dashboard;
