import React, { useState } from "react";
import { CheckedIcons } from "../../assets/icons/IconsSvg";
import Checkout from "../Checkout/Checkout";
import styles from "./listsCart.module.css";
type propsType = {
  clickMe?: any;
  icon?: any;
  onClick?: () => void;
  listData?: any;
};
const ListsCart = (props: propsType) => {
  const [clickMe, setClickMe] = useState(false);
  return (
    <div
      className={styles.container}
      onClick={props.onClick}
      style={{
        background: clickMe || props.clickMe ? " #EFE6FF" : "",
      }}
    >
      <div className={styles.icons}>
        {props.icon ? (
          <div onClick={() => setClickMe((a) => !a)}>
            <Checkout clickMe={clickMe || props.clickMe} />
          </div>
        ) : null}
      </div>

      <div className={styles.lists_cart}>
        <div style={{ flex: 0.5 }} className={styles.item}>
          <p>7709</p>
        </div>
        <div style={{ flex: 1 }} className={styles.item}>
          <p>Gafur</p>
          <p>Toshkent shah</p>
        </div>
        <div style={{ flex: 1 }} className={styles.item}>
          <h4>Elyor</h4>
          <p>Nukus shah</p>
        </div>
        <div style={{ flex: 1 }} className={styles.item}>
          <p>Avex Electrics
            Elektr isitgichi </p>
        </div>
        <div style={{ flex: 1 }} className={styles.item}>
          <p>100K shop</p>
        </div>
        <div style={{ flex: 1 }} className={styles.item}>
          <p>2022.18.09</p>
          <p>Dush, 12:09</p>
        </div>
        <div style={{ flex: 1 }} className={styles.item}>
          <div className={styles.item_btn}>
            <p>Tasdiqlangan</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListsCart;
