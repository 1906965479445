import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import tokens from "../api/tokens";
import { APP_ROUTES } from "./Route";

const ProtectedRoute = ({ children }: any) => {
    const token = tokens.getAccessToken();
    const navigate = useNavigate()

    useEffect(() => {

        if (!token) {
            console.log("token topiladi");
            navigate(APP_ROUTES.LOGIN);
        }
    }, [token])

    console.log({ token })


    return (
        children
    )
};


export default ProtectedRoute