import { makeAutoObservable, toJS } from 'mobx';
import api from '../../api';
import { Operation } from '../../utils/Operation';
import {
	AllUsersStorage,
	AllUsersStorageResponse,
} from '../../types/store/services/Users.types';

export default class UsersStore {
	private allUserStorages = new Operation<AllUsersStorageResponse>(
		{} as AllUsersStorageResponse
	);
	public checkedUser = {} as AllUsersStorage;

	constructor() {
		makeAutoObservable(this);
	}

	public getAllUsersStorages = async () => {
		await this.allUserStorages.run(api.getAllUsersStorages);
	};

	public get allUsersStorageDataList() {
		return toJS(this.allUserStorages.data.data) || [];
	}

	public clear = () => {
		this.allUserStorages.clear();
	};

	public setCheckedUser = (newUser: AllUsersStorage) => {
		this.checkedUser = newUser;
	};
}
