import { useState } from "react";
import ListItem from "../../ListItem/ListItem";
import styles from "./ExpressNavbar.module.css";
type PropsType = {
  data?: any;
  title?: string;
  info?: any;
  setActive?: any;
};

const ExpressNavbar = (props: PropsType) => {
  const [clickMe, setClickMe] = useState("Sozlamalar");
  const onClivj = ({ title }: { title: string }) => {
    setClickMe(title);
    props?.setActive(title);
  };

  return (
    <div className={styles.navbar}>
      <div
        className={styles.left_container}
        style={{ justifyContent: props.data ? "space-between" : "center" }}
      >
        <div className={styles.title_box}>
          <h1>{props.title}</h1>
          {props.info ? <p>{props.info}</p> : null}
        </div>
        {props.data ? (
          <div className={styles.list_box_div}>
            <ul className={styles.list_box}>
              {props.data?.map((item: any, index: number) => {
                return (
                  <ListItem
                    key={index}
                    title={item.title}
                    setClickMe={onClivj}
                    clickMe={clickMe}
                    color={item.color}
                  />
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExpressNavbar;
