import React, { CSSProperties } from "react";
import styles from "./ItemCartSort.module.css";
type PropsType = {
  icon?: any;
  info?: any;
  cartSortStyle?: CSSProperties;
};
const ItemCartSort = (props: PropsType) => {
  return (
    <div className={styles.cart_box} style={{ ...props.cartSortStyle }}>
      <div className={styles.icons}>{props.icon}</div>
      <p>{props.info}</p>
    </div>
  );
};

export default ItemCartSort;
