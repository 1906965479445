import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AddIcons,
  BackIcon,
  CheckoutCircleIcons,
  PrintIcon,
} from "../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../components/ButtonView/view";
import TextView from "../../../../components/TextView/view";
import { COLORS } from "../../../../constants/colors";
import styles from "./style.module.css";

const AddedMail = () => {
  const navigation = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.header}>
          <span>
            <CheckoutCircleIcons fill="red" />
          </span>
          <TextView
            textSize="24"
            title="Pochta qo‘shildi"
            style={{ color: COLORS.bl1000 }}
          />
        </div>
        <div className={styles.listBox}>
          <TextView className={styles.text} textSize="18" title="yuboruvchi:" />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="Javohir Karimov"
              textSize="17"
            />
            <TextView title="+998 90 000-90-77" textSize="15" />
          </div>
        </div>
        <div className={styles.listBox}>
          <TextView
            className={styles.text}
            textSize="18"
            title="qabul qiluvchi:"
          />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="Anvar Rustamov"
              textSize="17"
            />
            <TextView title="+998 90 000-90-77" textSize="15" />
          </div>
        </div>
        <div className={styles.listBox}>
          <TextView className={styles.text} textSize="18" title="qayerdan:" />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="Toshkent shahar"
              textSize="17"
            />
            <TextView title="+998 90 000-90-77" textSize="15" />
          </div>
        </div>
        <div className={styles.listBox}>
          <TextView className={styles.text} textSize="18" title="qayerga:" />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="Qoraqolpag’iston respublikasi"
              textSize="17"
            />
            <TextView title="+998 90 000-90-77" textSize="15" />
          </div>
        </div>
        <div className={styles.listBox}>
          <TextView className={styles.text} textSize="18" title="jo‘natma:" />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="Aux konditsioneri"
              textSize="17"
            />
          </div>
        </div>
        <div className={styles.listBox}>
          <TextView
            className={styles.text}
            textSize="18"
            title="transport turi:"
          />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="Yuk avtomabili"
              textSize="17"
            />
          </div>
        </div>
        <div className={styles.listBox}>
          <TextView
            className={styles.text}
            textSize="18"
            title="tetkazish summasi:"
          />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="34.000 uzs"
              textSize="17"
            />
          </div>
        </div>
        <div className={styles.listBox}>
          <TextView className={styles.text} textSize="18" title="sug’urta:" />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="22.000 uzs"
              textSize="17"
            />
          </div>
        </div>
        <div className={styles.listBox} style={{ border: 0 }}>
          <TextView
            className={styles.text}
            textSize="19"
            title="umumiy summa:"
          />
          <div className={styles.listRight}>
            <TextView
              style={{ color: COLORS.bl1000 }}
              title="124.000 uzs"
              textSize="18"
            />
          </div>
        </div>
        <div className={styles.btnBox}>
          <ButtonView
            buttonSize="small"
            buttonType="primary"
            title="Chop etish"
            style={{
              fontSize: "17px",
              backgroundColor: COLORS.sc700,
              border: "1px solid #7638DB",
            }}
            icon={<PrintIcon />}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonView
          buttonSize="large"
          buttonType="primaryOutline"
          title="Ortga"
          style={{
            border: "1px solid #2F8AF5",
            color: "#2F8AF5",
          }}
          icon={<BackIcon />}
          clickMe={() => navigation("/mails")}
        />
        <ButtonView
          buttonSize="large"
          buttonType="primary"
          title="Yangi pochta qo‘shish  "
          icon={<AddIcons />}
          clickMe={() => navigation("/add-mail")}
        />
      </div>
    </div>
  );
};

export default AddedMail;
