import React from "react";
import { CSSProperties } from "styled-components";
import styles from "./ExpresCart.module.css";
type PropsType = {
  icon?: any;
  titleStyle?: CSSProperties;
  title?: any;
  infoStyle?: CSSProperties;
  info?: any;
  iconStyle?: CSSProperties;
  cartBoxStyle?: CSSProperties;
};
const ExpresCart = (props: PropsType) => {
  return (
    <div className={styles.cart_box} style={{ ...props.cartBoxStyle }}>
      <div className={styles.icons} style={{ ...props.iconStyle }}>
        {props.icon}
      </div>
      <div className={styles.gr_labl}>
        <h4 style={{ ...props.titleStyle }}>{props.title}</h4>
        <p style={{ ...props.infoStyle }}>{props.info}</p>
      </div>
    </div>
  );
};

export default ExpresCart;
