import React, { CSSProperties } from "react";
import TextView from "../TextView/view";
import styles from "./style.module.css";
interface Props {
  placeholder?: string;
  icon?: any;
  rightIcon?: any;
  style?: CSSProperties;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  type?: string;
  value?: string;
  lable?: string;
  inputSize: "large" | "small";
  labelSize?: string;
}

const InputView: React.FC<Props> = ({
  placeholder,
  icon,
  rightIcon,
  style,
  onChange,
  type,
  value,
  lable,
  inputSize,
  labelSize,
}) => {
  return (
    <div style={{ ...style }}>
      <TextView title={lable} textSize={labelSize} />
      <div className={`${styles[inputSize]}`}>
        <div className={styles.iconB}>{icon}</div>
        <input type={type} placeholder={placeholder} onChange={onChange} value={value} />
        <div className={styles.iconB} style={{ cursor: "pointer" }}>
          {rightIcon}
        </div>
      </div>
    </div>
  );
};

export default InputView;
