import { MessageIcons } from "../../../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../../../components/ButtonView/view";
import FilterList from "../../../../../../components/FilterList.tsx/FilterList";
import SearchView from "../../../../../../components/SearchView/SearchView";
import styles from "./Comments.module.css";
import CommentsList from "./CommentsList/CommentsList";
const data = [
  { id: 0, title: "Vaqt", flex: 0.5 },
  { id: 1, title: "Matn" },
  { id: 2, title: "Muallif", flex: 0.7 },
];
type propsType = {
  setOpenModal?: any;
};
const Comments = ({ setOpenModal }: propsType) => {
  return (
    <div className={styles.comment_container}>
      <div className={styles.search_and_comment}>
        <div className={styles.search}>
          <SearchView />
        </div>
        <ButtonView
          buttonSize={"large"}
          buttonType={"primary"}
          icon={<MessageIcons />}
          clickMe={setOpenModal}
          style={{ backgroundColor: "#9B9B9B", border: "none" }}
          title={"Komment qo‘shish"}
        />
      </div>
      <div className={styles.lists_filter}>
        <div className={styles.filter}>
          <FilterList data={data} />
        </div>
        <div className={styles.lists}>
          <CommentsList />
          <CommentsList />
          <CommentsList />
          <CommentsList />
          <CommentsList />
          <CommentsList />
          <CommentsList />
          <CommentsList />
          <CommentsList />
          <CommentsList />
        </div>
      </div>
    </div>
  );
};

export default Comments;
