import { useState } from "react";
import {
  BinaryLocation,
  Cancaled,
  Deliver,
  Dept,
  EditIcon,
  ExitIcons,
  Minus,
  SoldOut,
  SuitCase,
} from "../../../../assets/icons/IconsSvg";
import personImg from "../../../../assets/images/CourierImage.png";
import PersonItem from "../../../../components/PersonItem/PersonItem";
import { COLORS } from "../../../../constants/colors";
import styles from "./style.module.css";
import EnterAddMailModal from "../../../../components/SortModals/EnterAddMailModal/EnterAddMailModal";
import useVisible from "../../../../hooks/useVisible";
import EnterInNavbar from '../../../../components/Navbar/EnterInNavbar/EnterInNavbar'
import Mails from './components/mails/mails'
import CaseHistory from './components/caseHistory/caseHistory'
import Transactions from './components/transactions/transactions'
import CourierAttech from '../../../../components/CourierAttech/CourierAttech'



const data2 = [
  {
    id: 1,
    title: "Pochtalar",
  },
  {
    id: 2,
    title: "Tranzaksiyalar",
  },
  {
    id: 2,
    title: "Amallar tarixi",
  },
];

const InEnter = () => {
  const [active, setActive] = useState("");

  let saidbar = "Pochtalar";

  if (active === "Pochtalar") {
    saidbar = "Pochtalar";
  } else if (active === "Tranzaksiyalar") {
    saidbar = "Tranzaksiyalar";
  } else if (active === "Amallar tarixi") {
    saidbar = "Amallar tarixi";
  }
  const modal = useVisible();
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <EnterInNavbar
          data={data2}
          id={77700}
          title={"Kuryer listi"}
          setActive={setActive}
        />
      </div>
      <div className={styles.rowBox}>
        {saidbar === "Pochtalar" ? <Mails setOpenModal={modal.show} /> : null}
        {saidbar === "Tranzaksiyalar" ? <Transactions /> : null}
        {saidbar === "Amallar tarixi" ? <CaseHistory /> : null}
        <div className={styles.rightBox}>
          <div
            className={styles.rightBoxItemOne}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="Sardor Tursunov"
              info="+998 90 009-55-33"
              img={personImg}
              icon={<EditIcon />}
              TitleStyle={{ fontSize: "15px" }}
              IconStyle={{
                backgroundColor: COLORS.pr200,
                borderRadius: "50%",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="340 ta"
              info="Pochtalar soni"
              avatarIconUrl={<SuitCase />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: COLORS.sc100,
              }}
              IconStyle={{
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="23 ta"
              info="Yo’lda"
              avatarIconUrl={<BinaryLocation />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: COLORS.sc100,
              }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="84 ta"
              info="Sotilgan"
              avatarIconUrl={<SoldOut />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: "#E6FFE6",
              }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="7 ta"
              info="Bekor qilingan"
              avatarIconUrl={<Cancaled />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: "#FFEFF3",
              }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="2.994.000 uzs"
              info="Kuryer qarzi"
              avatarIconUrl={<Dept />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: "#FFEFF3",
              }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="804.000 uzs"
              info="Plus"
              avatarIconUrl={<ExitIcons color="#1CCA18" />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: "#E6FFE6",
              }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="804.000 uzs "
              info="Plus"
              avatarIconUrl={<Minus />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: "#FFEFF3",
              }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div className={styles.rightBoxItem}>
            <PersonItem
              title="804.000 uzs "
              info="Plus"
              avatarIconUrl={<Deliver />}
              avatarStyle={{
                borderRadius: "8px",
                backColor: COLORS.sc100,
              }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
        </div>
      </div>
      <EnterAddMailModal onCancel={modal.hide} onOpen={modal.visible} />
    </div>
  );
};

export default InEnter;
