import React from "react";

import ButtonView from "../../../components/ButtonView/view";
import InputView from "../../../components/InputView/view";
import TextView from "../../../components/TextView/view";
import { ASSETS } from "../../../constants/imageRequire";
import styles from "./style.module.css";
import { useNavigate } from "react-router-dom";
import {
  CheckoutIcons,
  ContactIcons,
  InternetIcons,
  LockIcons,
  LoginNewPassIcons,
} from "../../../assets/icons/IconsSvg";
import DropdownTr from "../../../components/DropdownTr/view";

const LoginNewPass = () => {
  const navigation = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.leftBox}>
        <img className={styles.logincam} src={ASSETS.logincam} alt="" />
        <img className={styles.logo} src={ASSETS.logo} alt="" />
      </div>
      <div className={styles.rightBox}>
        <div className={styles.imgBox}>
          <DropdownTr style={{ position: "absolute", right: "25px", top: "25px" }} />
          <LoginNewPassIcons width="350px" />
        </div>
        <div className={styles.form}>
          <InputView
            lable="Telefon raqam yoki E-mail (tizimga kirish kodini olish uchun)"
            icon={<ContactIcons />}
            type="text"
            placeholder="javohirtoirov99@gmil.com"
            inputSize="large"
          />
          <InputView
            lable="Yangi parolni kiriting"
            icon={<ContactIcons />}
            type="text"
            placeholder="*********"
            style={{ marginTop: "25px" }}
            inputSize="large"
          />
          <InputView
            lable="Parolni takrorlang"
            icon={<LockIcons />}
            type="text"
            placeholder="12345QW"
            style={{ marginTop: "25px" }}
            inputSize="large"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "25%",
          }}
        >
          <ButtonView
            clickMe={() => navigation("/")}
            icon={<CheckoutIcons />}
            title="So‘ro‘v yuborish"
            buttonSize={"large"}
            buttonType={"primary"}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginNewPass;
