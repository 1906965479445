export enum APP_ROUTES  {
    AUTH = '/auth',
    HOME = '/',
    
    LOGIN = '/auth/login',
    LOGIN_NEW_PASS = '/auth/login-new-pass',
    REGISTER = '/auth/sign-up',
    SIGN_UP_GET_CODE = '/auth/sign-up-get-code',

    MAILS_VIEW = '/mails',
    COURIERS_VIEW = '/couriers',
    ENTER_VIEW = '/enter',
    OUTPUT_VIEW = '/output',
    CALCULATIONS_VIEW = '/calculations',
    DASHBOARD_VIEW = '/dashboard',
    YUZK_VIEW = '/100k',
    MAILS_INFO_VIEW = '/mails-info',
    ADD_MAIL_VIEW = '/add-mail',
    ADDED_MAIL_VIEW = '/added-mail',
    IN_ENTER_VIEW = '/in-enter',


    SIDEBAR = '/sidebar',

    ERROR = '/error',
};

