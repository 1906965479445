import { Steps } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  CarIcon,
  CheckoutIcons,
  Currency,
  EditIcon,
  HomeIcon,
  Insurance,
  LocationIcon,
  MobileIcon,
  NextIcon,
  PersonIcon,
  SecondLocation,
  Sender,
  SuitCase,
  TruckIcon,
} from "../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../components/ButtonView/view";
import InputView from "../../../../components/InputView/view";
import SelectInputView from "../../../../components/SelectInput/view";
import TextView from "../../../../components/TextView/view";
import styles from "./style.module.css";

const firstData = [
  {
    id: 1,
    title: "Yengil avtomobil",
    icon: <CarIcon />,
  },
  {
    id: 2,
    title: "Yuk mashinasi",
    icon: <TruckIcon />,
  },
  {
    id: 3,
    title: "Piyoda",
    icon: <PersonIcon />,
  },
];
const secondData = [
  {
    id: 1,
    title: "Samarqand",
  },
  {
    id: 2,
    title: "Tashkent",
  },
  {
    id: 3,
    title: "Jissax",
  },
];

const steps = [
  {
    title: "Yetkazish ma’lumotlari",
    content: (
      <>
        <div className={styles.formContent}>
          <div style={{ width: "70%" }}>
            <TextView title="Yuboruvchining ma’lumotlari" />
            <InputView
              inputSize="large"
              lable="To‘liq ism"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="Isim va Familiya"
              icon={<Sender />}
            />
            <InputView
              inputSize="large"
              lable="Telefon raqami"
              labelSize="16"
              placeholder="+998 00 000-12-34"
              style={{ marginTop: "30px" }}
              icon={<MobileIcon />}
            />
            <TextView
              textSize="16"
              style={{ marginTop: "30px" }}
              title="Qayerdan (Yuborish manzili)"
            />
            <SelectInputView dataBase={secondData} icon={<LocationIcon />} />
            <InputView
              inputSize="large"
              lable="Aniq manzil"
              labelSize="16"
              placeholder="Ko‘cha nomi, uy raqami"
              style={{ marginTop: "30px" }}
              icon={<SecondLocation />}
            />
          </div>
        </div>
        <div className={styles.formContent} style={{ marginTop: "30px" }}>
          <div style={{ width: "70%" }}>
            <TextView title="Yuboruvchining ma’lumotlari" />
            <InputView
              inputSize="large"
              lable="To‘liq ism"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="Isim va Familiya"
              icon={<Sender />}
            />
            <InputView
              inputSize="large"
              lable="Telefon raqami"
              labelSize="16"
              placeholder="+998 00 000-12-34"
              style={{ marginTop: "30px" }}
              icon={<MobileIcon />}
            />
            <TextView
              textSize="16"
              style={{ marginTop: "30px" }}
              title="Qayerdan (Yuborish manzili)"
            />
            <SelectInputView dataBase={secondData} icon={<LocationIcon />} />
          </div>
        </div>
      </>
    ),
  },
  {
    title: "Pochta  ma’lumotlari",
    content: (
      <>
        <div className={styles.secondBox}>
          <div className={styles.columnBox}>
            <TextView title="Yuboruvchining ma’lumotlari" />
            <div className={styles.SenderData}>
              <TextView
                className={styles.textBox}
                title="Sardor Bahriddinov"
                textSize="16"
              />
              <span style={{ margin: "0 5px" }}>/</span>
              <TextView
                className={styles.textBox}
                title="+998 90 999-00-44"
                textSize="16"
              />
              <span style={{ margin: "0 5px" }}>/</span>
              <TextView
                className={styles.textBox}
                title="Qoraqolpag’iston respublikasi, Nukus shahar"
                textSize="16"
              />
            </div>
          </div>
          <div className={styles.deleteIcon}>
            <EditIcon />
          </div>
        </div>
        <div className={styles.secondBox} style={{ marginTop: "20px" }}>
          <div className={styles.columnBox}>
            <TextView title="Qabul qiluvchining ma’lumotlari" />
            <div className={styles.SenderData}>
              <TextView
                className={styles.textBox}
                title="Javohir Karimov"
                textSize="16"
              />
              <span style={{ margin: "0 5px" }}>/</span>
              <TextView
                className={styles.textBox}
                title="+998 90 000-09-99"
                textSize="16"
              />
              <span style={{ margin: "0 5px" }}>/</span>
              <TextView
                className={styles.textBox}
                title="Toshkent shahar, Uchtepa tumani"
                textSize="16"
              />
            </div>
          </div>
          <div className={styles.deleteIcon}>
            <EditIcon />
          </div>
        </div>
        <div className={styles.formContent} style={{ marginTop: "30px" }}>
          <div style={{ width: "70%" }}>
            <TextView title="Pochtaning ma’lumotlari" />
            <InputView
              inputSize="large"
              lable="Jo‘natma nomi"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="Misol uchun Avtomobil uchun ehtiyot qisim"
              icon={<SuitCase />}
            />
            <TextView
              textSize="16"
              style={{ marginTop: "30px" }}
              title="Trasport turi"
            />
            <SelectInputView dataBase={firstData} icon={<TruckIcon />} />
            <InputView
              inputSize="large"
              lable="Yetkazish summasi *"
              labelSize="16"
              placeholder="0 uzs"
              style={{ marginTop: "30px" }}
              icon={<Currency />}
            />
            <InputView
              inputSize="large"
              lable="Sug’urta summasi"
              labelSize="16"
              placeholder="0 uzs"
              style={{ marginTop: "30px" }}
              icon={<Insurance />}
            />
            <InputView
              inputSize="large"
              lable="Umumiy summasi *"
              labelSize="16"
              placeholder="0 uzs"
              style={{ marginTop: "30px" }}
              icon={<HomeIcon />}
            />
          </div>
        </div>
      </>
    ),
  },
];

const AddMail: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const navigation = useNavigate();
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <div className={styles.container}>
      <div className={styles.headerBox}>
        <TextView title="Pochta qo‘shish" />
        <Steps
          className={styles.steps}
          style={{ color: "red" }}
          current={current}
          items={items}
        />
      </div>
      <div className={styles.body}>
        <div className={styles.content}>{steps[current].content}</div>
        <div className={styles.footer}>
          {current < steps.length - 1 && (
            <ButtonView
              buttonSize="small"
              buttonType="primary"
              title="Keyingisi"
              icon={<NextIcon />}
              // disabled
              clickMe={() => next()}
            />
          )}
          {current === steps.length - 1 && (
            <ButtonView
              buttonType="primary"
              buttonSize="small"
              title="Tasdiqlash"
              icon={<CheckoutIcons />}
              clickMe={() => navigation("/added-mail")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddMail;
