import React from "react";
import { CSSProperties } from "styled-components";
import styles from "./style.module.css";

interface Props {
  imageUrl?: string;
  iconUrl?: any;
  style?: CSSProperties;
  borderRadius?: string;
  backColor?: string;
}

const AvatarView: React.FC<Props> = ({
  imageUrl,
  style,
  iconUrl,
  borderRadius = 50,
  backColor = "#FFEFF3",
}) => {
  return (
    <div
      className={styles.imageBox}
      style={{
        borderRadius: `${borderRadius}%`,
        backgroundColor: `${backColor}`,
        ...style,
      }}
    >
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="" />
      ) : iconUrl ? (
        <span className={styles.icon}>{iconUrl}</span>
      ) : (
        <span></span>
      )}
    </div>
  );
};

export default AvatarView;
