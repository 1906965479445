import React from "react";
import { DotIcons } from "../../../../../../../assets/icons/IconsSvg";
import styles from "./TransactionsList.module.css";
const TransactionsList = () => {
  return (
    <div className={styles.container}>
      <div style={{ flex: 0.5 }} className={styles.item}>
        <p>5032</p>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <div className={styles.item_btn}>
          <DotIcons />
          <p>Qarzga berildi </p>
        </div>
      </div>
      <div style={{ flex: 0.7 }} className={styles.item}>
        <p>3.080.000 uzs</p>
        <p></p>
      </div>
      <div style={{ flex: 0.7 }} className={styles.item}>
        <p>2022.18.09 </p>
        <p>Dush, 12:09</p>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <p>Uchtepadagi kuryergaa berildi</p>
      </div>
    </div>
  );
};

export default TransactionsList;
