import React, { useState } from "react";
import AvatarView from "../Avatar/view";
import Checkout from "../Checkout/Checkout";
import styles from "./CourierCart.module.css";
import ListCart from "./ListCart/ListCart";
import CoureImage from "../../assets/images/CourierImage.png";
import PersonItem from "../PersonItem/PersonItem";
import {
  HeshtecIcons,
  ListIcons,
  WalletIcons,
} from "../../assets/icons/IconsSvg";
import { COLORS } from "../../constants/colors";
const CourierCart = () => {
  const [clickMe, setClickMe] = useState(false);
  return (
    <div
      className={styles.container}
      style={{ backgroundColor: clickMe ? " #EFE6FF" : "" }}
    >
      <div
        className={styles.checkout_box}
        onClick={() => setClickMe((a) => !a)}
      >
        <Checkout clickMe={clickMe} />
      </div>
      <div className={styles.right_box}>
        <PersonItem
          img={CoureImage}
          icon={<HeshtecIcons />}
          ItemStyle={{}}
          title="Courier full name"
          info="+998 90 000-00-00"
          TitleStyle={{ color: "#000000", fontSize: "20px" }}
          InfoStyle={{ color: "#2B2B2B", fontSize: "14px" }}
          IconStyle={{
            backgroundColor: "#F7F7F7",
            borderRadius: "8px",
          }}
          iconText="00000"
        />
        <div className={styles.lists_box}>
          <ListCart
            icon={<WalletIcons />}
            iconStyle={{ backgroundColor: COLORS.ss400 }}
            title={"00.000.000 uzs"}
            titleStyle={{ color: COLORS.ss400 }}
            info={"Hisobida"}
            infoStyle={{ color: COLORS.bl800, fontSize: "14px" }}
          />
          <ListCart
            icon={<ListIcons />}
            iconStyle={{ backgroundColor: COLORS.pr700 }}
            title={"00"}
            titleStyle={{ color: COLORS.pr700 }}
            info={"Hozirda mavjud listlari"}
            infoStyle={{ color: COLORS.bl800, fontSize: "14px" }}
          />
          <ListCart
            icon={<ListIcons />}
            iconStyle={{ backgroundColor: COLORS.pr700 }}
            title={"000"}
            titleStyle={{ color: COLORS.ss400 }}
            info={"Bajarilgan listlar"}
            infoStyle={{ color: COLORS.bl800, fontSize: "14px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default CourierCart;
