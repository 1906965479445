import React, { useState } from "react";
import {
  BottomIcons,
  CheckedIcons,
  CheckoutCircleIcons,
  TopIcons,
} from "../../../assets/icons/IconsSvg";
import styles from "./ListModalView.module.css";
type PropsType = {
  ListModaldata?: any;
  onClick?: any;
  title?: string;
};
const ListModalView = (props: PropsType) => {
  const [click, setClick] = useState("");
  return (
    <div
      className={styles.container}
      style={{ display: props.onClick ? "flex" : "none" }}
    >
      <div className={styles.sort_navbar}>
        <p>{props.title}</p>
        <div className={styles.icons}>
          <TopIcons />
          <BottomIcons />
        </div>
      </div>
      <div className={styles.foter_list}>
        {props.ListModaldata?.map((item: any, index: number) => {
          return (
            <div
              key={index}
              className={styles.item}
              onClick={() => setClick(item.info)}
              style={{ backgroundColor: click === item.info ? "#EFE6FF" : "" }}
            >
              <p>{item.info}</p>
              <div className={styles.checkout}>
                {click === item.info ? <CheckoutCircleIcons /> : ""}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ListModalView;
