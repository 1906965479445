import React, { useState } from "react";
import FilterList from "../../../../components/FilterList.tsx/FilterList";
import Hovered from "../../../../components/Hovered/Hovered";
import styles from "./Dashboard.module.css";
const data = [
  {
    id: 0,
  },
];
const DashboardView = () => {
  const [clickMe, setClickMe] = useState(false);
  return (
    <div className={styles.container}>
      Dashboard
      {/* <div>
        <FilterList setClickMe={setClickMe} clickMe={clickMe} icon={true} />
      </div>
      {data.map((item) => {
        return <Hovered clickMe={clickMe} icon={true} />;
      })} */}
    </div>
  );
};

export default DashboardView;
