import React from 'react'
import { AddIcons } from '../../../../../../assets/icons/IconsSvg'
import ButtonView from '../../../../../../components/ButtonView/view'
import FilterList from '../../../../../../components/FilterList.tsx/FilterList'
import SortView from '../../../../../../components/SortView/SortView'
import TransactionsList from '../../../MailsInfoView/components/Transactions/TransactionsList/TransactionsList'
import TransactionList from '../transactionList/transactionList'
import styles from "./style.module.css"

const SortData = [
    {
        id: 0,
        title: "ID",
        flex: 0.5
    },
    {
        id: 0,
        title: "Holat",
        flex: 1
    },
    {
        id: 0,
        title: "Summasi",
        flex: 1
    },
    {
        id: 0,
        title: "Vaqt",
        flex: 1
    },
    {
        id: 0,
        title: "Izoh",
        flex: 1
    },
]

const listData = [
    {
        id: 5032,
        condition: "Qarzga berildi",
        price: "3.080.000 uzs",
        time: "2022.18.09 Dush, 12: 09",
        coment: "Uchtepadagi kuryergaa berildi"
    },
    {
        id: 5032,
        condition: "Qarzga berildi",
        price: "3.080.000 uzs",
        time: "2022.18.09 Dush, 12: 09",
        coment: "Uchtepadagi kuryergaa berildi"
    },
    {
        id: 5032,
        condition: "Qarzga berildi",
        price: "3.080.000 uzs",
        time: "2022.18.09 Dush, 12: 09",
        coment: "Uchtepadagi kuryergaa berildi"
    },
    {
        id: 5032,
        condition: "Qarzga berildi",
        price: "3.080.000 uzs",
        time: "2022.18.09 Dush, 12: 09",
        coment: "Uchtepadagi kuryergaa berildi"
    }
]

const Transactions = () => {
    return (
        <div className={styles.content}>
            <div className={styles.btnBox}>
                <ButtonView
                    buttonSize={'large'}
                    buttonType={'primary'}
                    title="Trazkasiya qo‘shish"
                    icon={<AddIcons />}
                    style={{
                        fontSize: "18px",
                    }}
                />
                <ButtonView
                    buttonSize={'large'}
                    buttonType={'primaryOutline'}
                    title="Kuryer hisobidan yechish"
                    style={{
                        marginLeft: "20px",
                        border: "1px solid #2F8AF5",
                        fontSize: "18px",
                        color: "#2F8AF5"
                    }}
                />
            </div>
            <div className={styles.listBox}>
                <FilterList data={SortData} />
            </div>
            <div>
                <TransactionList listData={listData} />
            </div>
        </div>
    )
}

export default Transactions