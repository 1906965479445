import React from 'react'
import { AddIcons, CommentIcon } from '../../../../../../assets/icons/IconsSvg'
import ButtonView from '../../../../../../components/ButtonView/view'
import FilterList from '../../../../../../components/FilterList.tsx/FilterList'
import CaseHistoryList from '../caseHistoryList/caseHistoryList'
import TransactionList from '../transactionList/transactionList'
import styles from "./style.module.css"

const SortData = [
    {
        id: 0,
        title: "Vaqt",
        flex: 0.5
    },
    {
        id: 0,
        title: "Matn",
        flex: 1
    },
    {
        id: 0,
        title: "Muallif",
        flex: 1
    },
]

const listData = [
    {
        time: "2022.18.09 Dush, 12: 09",
        text: "Toshkent shahardan chiqib ketdi",
        author: "Kuryer",
    },
    {
        time: "2022.18.09 Dush, 12: 09",
        text: "Toshkent shahardan chiqib ketdi",
        author: "Kuryer",
    },
    {
        time: "2022.18.09 Dush, 12: 09",
        text: "Toshkent shahardan chiqib ketdi",
        author: "Kuryer",
    },
]

const CaseHistory = () => {
    return (
        <div className={styles.content}>
            <div className={styles.btnBox}>
                <ButtonView
                    buttonSize={'large'}
                    buttonType={'primary'}
                    title="Komment qo‘shish"
                    icon={<CommentIcon color={"#fff"} />}
                    style={{
                        fontSize: "18px",
                    }}
                />
            </div>
            <div className={styles.listBox}>
                <FilterList data={SortData} />
            </div>
            <div>
                <CaseHistoryList listData={listData} />
            </div>
        </div>
    )
}

export default CaseHistory