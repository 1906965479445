import { useNavigate } from 'react-router-dom';
import {
	ContactIcons,
	LeftIcons,
	LockIcons,
} from '../../../assets/icons/IconsSvg';
import ButtonView from '../../../components/ButtonView/view';
import DropdownTr from '../../../components/DropdownTr/view';
import InputView from '../../../components/InputView/view';
import TextView from '../../../components/TextView/view';
import { ASSETS } from '../../../constants/imageRequire';
import styles from './style.module.css';
import { observer } from 'mobx-react';
import axios from 'axios';
import useRootStore from '../../../hooks/useRootStore';
import useRootState from '../../../hooks/useRootState';

const SignUp = observer(() => {
	const navigation = useNavigate();
	const store = useRootStore();
	const state = useRootState();

	var data = JSON.stringify({
		phone: '+998991234570',
		password: 'shox1223',
		name: 'beatae',
	});

	var config = {
		method: 'get',
		url: 'https://yuzka.uz/api/storage/all',
		headers: {
			'Content-Type': 'application/json',
			Accept: 'application/json',
			Authorization: 'Bearer 5|VO3Ifa1cL2ShnB5vXTIsoHtOVR0Cbozm585HRDg4',
			Cookie: 'XSRF-TOKEN=eyJpdiI6InlWRmNGaUQ1VkJlQXpiSVhaWFRhOEE9PSIsInZhbHVlIjoiak1EcVd5K2t4bUZrR0VBejFWMC9ENmFieTRUL1U2bVRsbHJhRXhLV2p6T3NiR2lUQ0hhNk1XMGNCbDR0QVRpZ04zT0FxcFRqQ2U1UmowOGNLSUs0UDJIQ2ppS1JoNlBnbHFTeHlVREhNK2REN1NiOFRaQzc3UHZsZEtScWlsbVAiLCJtYWMiOiJiNjFjYmNjYTkyMTdjY2ExZDczZTdkNmU4ZjYxNjMxYzc4NWZkNDc4NDVmMWM1YTI0NDM3MTgzYWM4ZTk5MWY2IiwidGFnIjoiIn0%3D; express_session=eyJpdiI6IlRVQXpQZU14V0NDNVk5Uy9Oa3hWb1E9PSIsInZhbHVlIjoiVU83bVJldzRlU2NVSUVHYkI4Wk9xSEJYNlBZanIvdDFaeVJBUWh3eit6RG1RSW1vNjZ6d1Fpc2RMUFZ6V21QQXBTRHloQUFjRVYzRVNsZFE3dU5JdWJad09iOXhidERzeE9ZRCtDSkJPZFFNN3Zha2ZxMnBVVDVJVHdoNGVYbGUiLCJtYWMiOiIxOWNlNTAyN2M5YmY0MTYwN2FlMDNmMTc5MDY5NmZkYWJlZThiMThlMDM3MDQ2YjA3MTU5ZmYwM2E3NjEzNjk5IiwidGFnIjoiIn0%3D',
		},
	};

	const handleSignUp = async () => {
		await axios(config)
			.then(function (response) {
				console.log(JSON.stringify(response.data));
			})
			.catch(function (error) {
				console.log(error);
			});
	};

	return (
		<div className={styles.container}>
			<div className={styles.leftBox}>
				<img className={styles.logincam} src={ASSETS.logincam} alt='' />
				<img className={styles.logo} src={ASSETS.logo} alt='' />
			</div>
			<div className={styles.rightBox}>
				<div className={styles.imgBox}>
					<DropdownTr
						style={{
							position: 'absolute',
							right: '25px',
							top: '25px',
						}}
					/>
					<img src={ASSETS.logintab} alt='' />
				</div>
				<div className={styles.form}>
					<InputView
						lable='Telefon raqam yoki E-mail kiriting'
						icon={<ContactIcons />}
						type='text'
						placeholder='+998 90 123-45-67'
						inputSize='large'
					/>
					<InputView
						lable='Parolni kiriting'
						icon={<LockIcons />}
						type='text'
						placeholder='*********'
						style={{ marginTop: '25px' }}
						inputSize='large'
					/>
				</div>
				<div className={styles.bottomBox}>
					<ButtonView
						clickMe={handleSignUp}
						icon={<LeftIcons />}
						title='Ro‘yhatdan o‘tish'
						buttonSize={'large'}
						buttonType={'primary'}
					/>
					<div
						style={{
							display: 'flex',
							marginTop: '60px',
						}}>
						<TextView title='Shaxsiy sahifam mavjud' />
						<TextView
							onClick={() => navigation('/auth/login')}
							style={{
								color: '#2F8AF5',
								cursor: 'pointer',
							}}
							title='Tizimga kirish'
						/>
					</div>
				</div>
			</div>
		</div>
	);
});

export default SignUp;
