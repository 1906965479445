export const COLORS = {
  pr1000: "#954111",
  pr900: "#B84301",
  pr800: "#D6540C",
  pr700: "#ED712B",
  pr600: "#F58342",
  pr500: "#F6965F",
  pr400: "#FFB68C",
  pr300: "#FFCEB1",
  pr200: "#FFE0CE",
  pr100: "#FFF6F0",
  sc1000: "#2C0073",
  sc900: "#3D029C",
  sc800: "#591ABF",
  sc700: "#7638DB",
  sc600: "#8E52F0",
  sc500: "#A26FF5",
  sc400: "#BA95F7",
  sc300: "#D6BCFF",
  sc200: "#EFE5FF",
  sc100: "#FAF6FF",
  tr1000: "#047C34",
  tr900: "#18A450",
  tr800: "#2DBE67",
  tr700: "#3FDA7D",
  tr600: "#50EC8E",
  tr500: "#70F2A4",
  tr400: "#ABFBCB",
  tr300: "#D6FDE6",
  tr200: "#D6FDE6",
  tr100: "#FDFFFE",
  bl1000: "#000000",
  bl900: "#161616",
  bl800: "#2B2B2B",
  bl700: "#424242",
  bl600: "#505050",
  bl500: "#6A6A6A",
  bl400: "#888888",
  bl300: "#9B9B9B",
  bl200: "#BEBEBE",
  bl100: "#D8D8D8",
  bg500: "#F8F9FB",
  bg400: "#FAFBFE",
  bg300: "#FBFBFD",
  bg200: "#FFFBFC",
  bg100: "#FFFDFA",
  wh1000: "#FFFFFF",
  wh900: "#FDFDFD",
  wh800: "#FAFAFA",
  wh700: "#F7F7F7",
  wh600: "#F4F4F4",
  wh500: "#F3F3F3",
  wh400: "#EEEEEE",
  wh300: "#E9E9E9",
  wh200: "#E2E2E2",
  wh100: "#DCDCDC",
  if700: "#034B9F",
  if600: "#1064C7",
  if500: "#2F80DE",
  if400: "#2F8AF5",
  if300: "#56A0F7",
  if200: "#78B3F8",
  if100: "#D3E7FF",
  ss700: "#067803",
  ss600: "#089904",
  ss500: "#0AB506",
  ss400: "#1CCA18",
  ss300: "#60DF5D",
  ss200: "#A5F6A3",
  ss100: "#E6FFE5",
  wr700: "#BD9609",
  wr600: "#D8A803",
  wr500: "#EAB90A",
  wr400: "#F5C519",
  wr300: "#FDDF76",
  wr200: "#FFEBA4",
  wr100: "#FFF9E5",
  dr700: "#910023",
  dr600: "#B60530",
  dr500: "#D30B3C",
  dr400: "#EE1E50",
  dr300: "#F75D82",
  dr200: "#FE9DB4",
  dr100: "#FFEFF3",
};
