import React from "react";
import {
    CancelIcon,
    CheckoutIcons,
    CourierNameIcon,
    MobileIcon,
    SecondLocation,
} from "../../assets/icons/IconsSvg";
import { COLORS } from "../../constants/colors";
import ButtonView from "../ButtonView/view";
import HeaderView from "../Header/view";
import InputView from "../InputView/view";
import SelectInputView from "../SelectInput/view";
import TextView from "../TextView/view";
import styles from "./style.module.css";

const data = [
    {
        id: 1,
        title: "Toshkent",
        // icon: <LocationIcon />
    },
    {
        id: 2,
        title: "Samarqand",
        // icon: <LocationIcon />
    },
    {
        id: 3,
        title: "Buxoro",
        // icon: <LocationIcon />
    },
];

interface Props {
    onOpen?: boolean;
    onCancel?: () => void;
    show?: any;
}

const AddCourier: React.FC<Props> = ({ onOpen, onCancel, show }) => {
    return (
        <div
            className={styles.container}
            style={{ right: onOpen ? "0%" : "-100%" }}
        >
            <div className={styles.bottom} onClick={onCancel}></div>
            <div className={styles.newBranchBox}>
                <HeaderView
                    title="Kuryer qo‘shish"
                    onCancel={onCancel}
                    icon={<CancelIcon />}
                />
                <div className={styles.formBox}>
                    <div>
                        <InputView
                            inputSize="large"
                            lable="Kuryer ismi"
                            placeholder="To‘liq ismi"
                            labelSize="17"
                            icon={<CourierNameIcon />}
                        />
                    </div>
                    <div className={styles.formItems}>
                        <InputView
                            inputSize="large"
                            lable="Telefon raqam"
                            placeholder="+998 90 000-00-00"
                            labelSize="17"
                            icon={<MobileIcon />}
                        />
                    </div>
                    <div className={styles.formItems}>
                        <TextView title="Kuryer hududi" textSize="17" />
                        <SelectInputView dataBase={data} icon={<SecondLocation />} />
                    </div>
                </div>
                <div className={styles.footer} onClick={onCancel}>
                    <ButtonView
                        buttonSize="small"
                        buttonType="primary"
                        title="Tasdiqlash"
                        icon={<CheckoutIcons />}
                    />
                    <ButtonView
                        buttonSize="small"
                        buttonType="primarySoft"
                        title="Bekor qilish"
                        style={{
                            color: COLORS.bl500,
                            marginRight: "10px",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default AddCourier;
