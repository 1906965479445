import React from "react";
import { SearchIcons, SortIcons } from "../../assets/icons/IconsSvg";
import styles from "./SearchView.module.css";
type PropsType = {
  ListModaldata?: any;
  setOnClick?: any;
  sort?: any;
  onChangeText?: any;
};
const SearchView = (props: PropsType) => {
  return (
    <div className={styles.container}>
      <div className={styles.one_box}>
        <div className={styles.search}>
          <SearchIcons />
        </div>
        <div className={styles.input}>
          <input
            type="text"
            placeholder="ID yoki Buyurtma nomini yozing"
            onChange={props.onChangeText}
          />
        </div>
      </div>
      {props?.sort ? (
        <div
          className={styles.sort}
          onClick={() => props.setOnClick((a: any) => !a)}
        >
          <SortIcons />
          <p>Tartiblash</p>
        </div>
      ) : null}
    </div>
  );
};

export default SearchView;
