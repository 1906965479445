import { useState } from "react";
import {
  Currency,
  HeshtecIcons,
  LocationIcon,
  Recipient,
  SecondLocation,
  Sender,
  SuitCase,
} from "../../../../assets/icons/IconsSvg";
import personImg from "../../../../assets/images/CourierImage.png";
import MailsInfoNavbar from "../../../../components/Navbar/MailsInfoNavbar/MailsInfoNavbar";
import PersonItem from "../../../../components/PersonItem/PersonItem";
import MailCommentModal from "../../../../components/SortModals/MailCommentModal/MailCommentModal";
import { COLORS } from "../../../../constants/colors";
import useVisible from "../../../../hooks/useVisible";
import Comments from "./components/Comments/Comments";
import Main from "./components/Main/Main";
import Transactions from "./components/Transactions/Transactions";
import styles from "./style.module.css";

const data2 = [
  {
    id: 1,
    title: "Asosiy",
  },
  {
    id: 2,
    title: "Kommentlar",
  },
  {
    id: 2,
    title: "Tranzaksiyalar",
  },
];

const MailsInfoView = () => {
  const [active, setActive] = useState("");
  const modal = useVisible();

  let sidebar = "Asosiy";

  if (active === "Asosiy") {
    sidebar = "Asosiy";
  } else if (active === "Kommentlar") {
    sidebar = "Kommentlar";
  } else if (active === "Tranzaksiyalar") {
    sidebar = "Tranzaksiyalar";
  }
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <MailsInfoNavbar
          data={data2}
          title={"Pochta ma’lumotlari"}
          setActive={setActive}
        />
      </div>
      <div className={styles.childContainer}>
        {sidebar === "Asosiy" ? <Main /> : null}
        {sidebar === "Kommentlar" ? (
          <Comments setOpenModal={modal.show} />
        ) : null}
        {sidebar === "Tranzaksiyalar" ? <Transactions /> : null}

        <div className={styles.rightBox}>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="Sardor, 98 777-00-33"
              info="Kuryer"
              icon={<HeshtecIcons />}
              iconText="77700"
              img={personImg}
              TitleStyle={{ fontSize: "16px" }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="Gafur, 90 999-00-08"
              info="Yuboruvchi"
              avatarIconUrl={<Sender />}
              avatarStyle={{ borderRadius: "8px" }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="Elyorga, 90 999-00-08"
              info="Qabul qiluvchi"
              avatarIconUrl={<Recipient />}
              avatarStyle={{ borderRadius: "8px" }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="340.000 uzs"
              info="Pochta narxi"
              avatarIconUrl={<Currency />}
              avatarStyle={{ borderRadius: "8px" }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="Toshkent shah, Uchtepa tum"
              info="Qayerdan"
              avatarIconUrl={<LocationIcon />}
              avatarStyle={{ borderRadius: "8px" }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div
            className={styles.rightBoxItem}
            style={{ borderBottom: "1px solid #dcdcdc" }}
          >
            <PersonItem
              title="Qoraqolpagiston resp, Nukus shah"
              info="Qayerga"
              avatarIconUrl={<SecondLocation />}
              avatarStyle={{ borderRadius: "8px" }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
          <div className={styles.rightBoxItem}>
            <PersonItem
              title="Avex Electrics Elektr isitgichi "
              info="Jo‘natma"
              avatarIconUrl={<SuitCase />}
              avatarStyle={{ borderRadius: "8px" }}
              IconStyle={{
                backgroundColor: COLORS.wh400,
                borderRadius: "8px",
                margin: 0,
              }}
            />
          </div>
        </div>
      </div>
      <MailCommentModal onCancel={modal.hide} onOpen={modal.visible} />
    </div>
  );
};

export default MailsInfoView;
