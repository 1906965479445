import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import AddCourier from '../../components/AddCourier/AddCourier';
import Dashboard from '../../components/Dashboard/view';
import LogOut from '../../components/LogOut/LogOut';
import NewBranch from '../../components/newBranch/view';
import SidebarView from '../../components/Sidebar/SidebarView';
import SettingsModule from '../../components/SortModals/100Kmodule/SettingsModule';
import LogOutModal from '../../components/SortModals/LogOutModal/LogOutModal';
import styles from './style.module.css';

const HomeView = () => {
	const [addComponents, setAddcomponents] = useState(false);
	const [addCourier, setAddCourier] = useState(false);
	const [logOut, setLogOut] = useState(false);
	const [show, setShow] = useState(false);
	const [logOutModal, setLogOutModal] = useState(false);

	const removeHandler = () => {
		setAddcomponents(false);
		setLogOut(false);
	};

	return (
		<div className={styles.container}>
			<div className={styles.Dashbort}>
				<Dashboard
					setAddcomponents={setAddcomponents}
					setLogOut={setLogOut}
				/>
				<SidebarView addComponents={addComponents} setShow={setShow} />
			</div>
			<div className={styles.RightContainer} onClick={removeHandler}>
				<Outlet />
			</div>
			<LogOut
				logOut={logOut}
				setLogOut={setLogOut}
				setLogOutModal={setLogOutModal}
			/>
			<NewBranch onOpen={show} onCancel={() => setShow(false)} />
			<LogOutModal
				setLogOutModal={setLogOutModal}
				logOutModal={logOutModal}
			/>
			<AddCourier
				onOpen={addCourier}
				onCancel={() => setAddCourier(false)}
			/>
		</div>
	);
};

export default HomeView;
