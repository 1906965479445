import React from "react";
import TextView from "../TextView/view";
import styles from "./style.module.css";
interface Props {
  title?: string;
  onClick?: () => void;
  onCancel?: () => void;
  icon?: any;
}

const HeaderView: React.FC<Props> = ({ title, onCancel, onClick, icon }) => {
  return (
    <div onClick={onClick} className={styles.headerBox}>
      <TextView title={title} />
      <span onClick={onCancel} className={styles.headerIcon}>
        {icon}
      </span>
    </div>
  );
};

export default HeaderView;
