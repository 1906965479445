import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

// all stores
import AuthStore from './AuthStore';
import UsersStore from './UsersStore';
import NotificationStore from './NotificationStore';

class RootStore {
	auth = new AuthStore();
	allUser = new UsersStore();
	notification = new NotificationStore();

	constructor() {
		makeAutoObservable(this);
		this.run();
	}

	private run = () => {
		const list = [this.allUser.getAllUsersStorages()];

		Promise.all(list)
			.then(() => console.log('Requests success!'))
			.catch(() => console.log('Requests failed!'));
	};
}

const rootStore = new RootStore();
export default createContext(rootStore);
