import React, { useState } from "react";
import styles from "./ExpressList.module.css";
type propsType = {
  clickMe?: any;
  icon?: any;
};
const ExpressList = (props: propsType) => {
  const [clickMe, setClickMe] = useState(false);
  return (
    <div className={styles.container}>
      <div style={{ flex: 0.7 }} className={styles.item}>
        <p>7709</p>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <button>
          <p>Qarzga berildi</p>
        </button>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <p>3.080.000 uzs</p>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <p>Qoraqolpagiston res,</p>
        <p>Nukus shah</p>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <p>Uchtepadagi kuryergaa berildi</p>
      </div>
    </div>
  );
};

export default ExpressList;
