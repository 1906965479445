import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BottomNewIcons,
  ChromeIcons,
  DataIcons,
  LeftNewIcons,
  RightNewIcons,
  ShopIcons,
} from "../../../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../../../components/ButtonView/view";
import FilterList from "../../../../../../components/FilterList.tsx/FilterList";
import Hovered from "../../../../../../components/Hovered/Hovered";
import SortView from "../../../../../../components/SortView/SortView";
import { COLORS } from "../../../../../../constants/colors";
import styles from "./NewOrders.module.css";
const data = [
  {
    id: 0,
    title: "ID",
    flex: 0.5,
    isVisible: false,
  },
  {
    id: 1,
    title: "Jo‘natma ",
    flex: 1,
    isVisible: true,
  },
  {
    id: 2,
    title: "Kimdan/Kimga ",
    flex: 1,
    isVisible: false,
  },
  {
    id: 3,
    title: "Qayerdan",
    flex: 1,
    isVisible: false,
  },
  {
    id: 4,
    title: "Qayerga",
    flex: 1,
    isVisible: false,
  },
  {
    id: 5,
    title: "Kuryer",
    flex: 1,
    isVisible: false,
  },
  {
    id: 6,
    title: "Oldindan to‘lo‘v",
    flex: 1,
    isVisible: false,
  },
];
const data2 = [
  {
    id: 0,
    title: "ID",
    flex: 0.5,
    isVisible: false,
  },
  {
    id: 1,
    title: "Jo‘natma ",
    flex: 1,
    isVisible: true,
  },
  {
    id: 2,
    title: "Kimdan/Kimga ",
    flex: 1,
    isVisible: false,
  },
  {
    id: 3,
    title: "Qayerdan",
    flex: 1,
    isVisible: false,
  },
  {
    id: 4,
    title: "Qayerga",
    flex: 1,
    isVisible: false,
  },
  {
    id: 5,
    title: "Kuryer",
    flex: 1,
    isVisible: false,
  },
  {
    id: 6,
    title: "Oldindan to‘lo‘v",
    flex: 1,
    isVisible: false,
  },
];
const data3 = [
  {
    id: 0,
    icon: <DataIcons />,
    info: "Sana bo‘yicha",
  },
  {
    id: 1,
    icon: <ChromeIcons />,
    info: "Hudud",
  },
  {
    id: 2,
    icon: <ChromeIcons />,
    info: "Hudud",
  },
];
const ListModaldata = [
  {
    id: 0,
    info: "Holat",
  },
  {
    id: 1,
    info: "Skladni tanlang",
  },
  {
    id: 2,
    info: "Yuboruvchi",
  },
  {
    id: 3,
    info: "Haydovchi",
  },
  {
    id: 4,
    info: "Qayerdan",
  },
  {
    id: 5,
    info: "Qayerga",
  },
];

const NewOrders = () => {
  const [isAll, setIsAll] = useState(false);
  const [newOrderList, updateNewOrderList] = useState(data2);

  const onChangeCheckbox = useCallback(
    (isVisible: boolean, id: number) => {
      updateNewOrderList((list) =>
        list.map((listItem) =>
          listItem.id === id ? { ...listItem, isVisible } : listItem
        )
      );
    },
    [newOrderList]
  );

  // useEffect(() => {
  //   const isAllClicked = newOrderList.every((listItem) => listItem.isVisible);
  //   setClickMe(isAllClicked);
  // }, [newOrderList]);

  const navigation = useNavigate();
  return (
    <div className={styles.lists_box}>
      <div className={styles.Sort}>
        <SortView
          data={data3}
          ListModaldata={ListModaldata}
          title={"Sana bo‘yicha"}
        />
      </div>
      <div className={styles.filter}>
        <FilterList
          setClickMe={setIsAll}
          clickMe={isAll}
          icon={true}
          data={data}
        />
      </div>
      <div className={styles.lists}>
        {newOrderList?.map((item, index) => {
          return (
            <Hovered
              key={index}
              isAll={isAll}
              value={item.isVisible}
              icon={true}
              onChange={(visible) => {
                onChangeCheckbox(visible, item.id);
              }}
              onClick={() => navigation("/mails-info")}
            />
          );
        })}
      </div>
      <div className={styles.foter}>
        <div className={styles.foter_left}>
          <ButtonView
            buttonSize={"large"}
            buttonType={"primary"}
            title={"Do‘konga biriktirish"}
            icon={<ShopIcons />}
            clickMe={() => {
              console.log(JSON.stringify(newOrderList, null, 2));
            }}
            style={{ backgroundColor: COLORS.sc700, fontSize: "15px" }}
          />
        </div>
        <div className={styles.foter_rigt}>
          <h4>Sahifalar tartibi</h4>
          <div className={styles.number_list}>
            <p>780 dan</p>
            <p>1-20</p>
            <div className={styles.bottom_icon}>
              <BottomNewIcons />
            </div>
          </div>
          <div className={styles.left_right}>
            <div className={styles.icon_box} style={{ marginRight: "20px" }}>
              <LeftNewIcons />
            </div>
            <p>1</p>
            <div className={styles.icon_box} style={{ marginLeft: "20px" }}>
              <RightNewIcons />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrders;
