import { BottomIcons, CheckedIcons, CheckoutColorIcons, TopIcons } from '../../assets/icons/IconsSvg';
import { COLORS } from '../../constants/colors';
import styles from "./style.module.css"
import TextView from '../TextView/view';
import React, { useState } from 'react';
interface Props {
    dataBase?: any,
    title?: string;
    icon?: any;
    id?: number;
}

const SelectInputView: React.FC<Props> = ({
    title,
    dataBase,
    icon,
    id
}) => {
    const [data, setData] = useState<{ id: number, icon: JSX.Element, title: string }>()
    function OnClick(id: number) {
        setData(dataBase.find((i: any) => i.id === id))
    }
    const [show, setShow] = useState(false)
    return (
        <div className={styles.container} style={{ borderRadius: show ? "10px 10px 0 0" : "10px" }}>
            <div className={styles.selected} onClick={() => setShow((e) => !e)} style={{ borderRadius: show ? "10px 10px 0 0" : "10px" }}>
                <div style={{ display: "flex" }}>
                    <span className={styles.iconB}>
                        {data?.icon ?
                            data.icon : icon
                        }
                    </span>
                    <TextView style={{ margin: 0 }} title={data?.title} className={styles.text} />
                </div>
                <span style={{
                    marginLeft: "10px"
                }}>
                    {show ?
                        <TopIcons /> :
                        <BottomIcons />
                    }
                </span>
            </div>
            {/* <div className={styles.content}> */}
            <div className={styles.hiddenBox}
                style={{ display: show ? "block" : "none", }}
                onClick={() => setShow(false)}>
                {
                    dataBase?.map((e: any, index: any) => {
                        return (
                            <div key={index}>
                                <label
                                    htmlFor={`${index}`}
                                    className={styles.selectBox}
                                    style={{ backgroundColor: data?.id == e.id ? COLORS.sc200 : COLORS.wh1000 }}
                                    onClick={() => OnClick(e.id)}
                                >
                                    <div style={{ display: "flex" }}>
                                        <span style={{ margin: "auto 5px" }}>{e.icon}</span>
                                        <TextView title={e.title} className={styles.text} />
                                    </div>
                                    <div
                                        className={styles.checkout_box}
                                        style={{ background: COLORS.bl200 }}
                                    >
                                        {data?.id === e.id ? <CheckoutColorIcons /> : null}
                                    </div>
                                </label>
                            </div>
                        )
                    })
                }
            </div>
            {/* </div> */}
        </div>
    );
}

export default SelectInputView;