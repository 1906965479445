import React from "react";
import FilterList from "../../../../../../components/FilterList.tsx/FilterList";
import ExpressList from "../ExpressList/ExpressList";
import styles from "./Transactions.module.css";
const data = [
  {
    id: 0,
    title: "ID",
    flex: "0.7",
  },
  {
    id: 1,
    title: "Holat",
    flex: "",
  },
  {
    id: 2,
    title: "Summasi",
    flex: "",
  },
  {
    id: 3,
    title: "Vaqt",
    flex: "",
  },
  {
    id: 4,
    title: "Izoh",
    flex: "",
  },
];
const dataCart = [
  {
    id: 0,
    title_1: "5032",
    title_2: "",
  },
  {
    id: 1,
    title_1: "Qarzga berildi",
    title_2: "",
  },
];
const Transactions = () => {
  return (
    <div className={styles.lists_box}>
      <FilterList data={data} />
      <div className={styles.lists}>
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
        <ExpressList />
      </div>
    </div>
  );
};

export default Transactions;
