import React, { CSSProperties } from 'react'
interface Props {
    title?: string;
    style?: CSSProperties,
    onClick?: () => void,
    className?: any,
    textSize?: string;
}

const TextView: React.FC<Props> = ({
    title,
    style,
    onClick,
    className,
    textSize = 20
}) => {
    return (
        <h3
            onClick={onClick}
            className={className}
            style={{
                display: "flex",
                alignItems: "center",
                fontStyle: "normal",
                fontWeight: " 600",
                fontSize: `${textSize}px`,
                letterSpacing: "-0.02em",
                color: "#505050",
                margin: "2px 3px",
                ...style
            }}>{title}</h3>
    )
}

export default TextView
