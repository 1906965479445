import { Avatar } from "@mui/material";
import React, { CSSProperties } from "react";
import { MenyuIcons, PersonIcon } from "../../assets/icons/IconsSvg";
import AvatarView from "../Avatar/view";
import styles from "./PersonItem.module.css";
type propsType = {
  img?: string;
  title?: string;
  info?: string;
  icon?: any;
  avatarIconUrl?: any;
  avatarStyle?: {
    borderRadius?: any;
    backColor?: any;
  };
  ItemStyle?: CSSProperties;

  TitleStyle?: {
    color?: string;
    fontSize?: string;
    fontWeght?: string;
    fontFamily?: string;
  };
  InfoStyle?: {
    color?: string;
    fontSize?: string;
  };
  IconStyle?: {
    backgroundColor?: string;
    borderRadius?: any;
    padding?: any;
    width?: any;
    height?: any;
    margin?: any;
  };
  setLogOut?: any;
  iconText?: any;
};
const PersonItem = (props: propsType) => {
  return (
    <div className={`${styles.exspres} `} style={{ ...props.ItemStyle }}>
      <div className={styles.exspres_image}>
        <AvatarView
          imageUrl={props.img}
          style={props.avatarStyle}
          backColor={props.avatarStyle?.backColor}
          iconUrl={props.avatarIconUrl}
        />
        <div className={styles.avatar_info}>
          <h3 style={props.TitleStyle}>{props.title}</h3>
          {props.info ? <p style={props.InfoStyle}>{props.info}</p> : null}
        </div>
      </div>
      {props.icon ? (
        <div
          style={props.IconStyle}
          className={styles.exspres_icon}
          onClick={() => props.setLogOut((a: any) => !a)}
        >
          <div>{props.icon}</div>
          {props.iconText ? (
            <p className={styles.iconText}>
              {props.iconText ? props.iconText : ""}
            </p>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default PersonItem;
