import React from "react";
import { ListIcons } from "../../../assets/icons/IconsSvg";
import styles from "./ListCart.module.css";

type propsTytpe = {
  icon?: any;
  iconStyle?: {
    backgroundColor?: string;
    borderRaduse?: number;
  };
  title?: any;
  titleStyle?: {
    color?: string;
    fontSize?: string;
  };
  info?: string;
  infoStyle?: {
    color?: string;
    fontSize?: string;
  };
};

const ListCart = (props: propsTytpe) => {
  return (
    <div className={styles.cart_box}>
      <div className={styles.icons} style={props.iconStyle}>
        {props.icon}
      </div>
      <div className={styles.gr_labl}>
        <h4 style={props.titleStyle}>{props.title}</h4>
        <p style={props.infoStyle}>{props.info}</p>
      </div>
    </div>
  );
};

export default ListCart;
