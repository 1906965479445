import React from "react";
import {
  BranchIcon,
  CancelIcon,
  CheckoutIcons,
  LocationIcon,
  MobileIcon,
} from "../../assets/icons/IconsSvg";
import { COLORS } from "../../constants/colors";
import ButtonView from "../ButtonView/view";
import HeaderView from "../Header/view";
import InputView from "../InputView/view";
import SelectInputView from "../SelectInput/view";
import TextView from "../TextView/view";
import styles from "./style.module.css";

const data = [
  {
    id: 1,
    title: "Toshkent",
    // icon: <LocationIcon />
  },
  {
    id: 2,
    title: "Samarqand",
    // icon: <LocationIcon />
  },
  {
    id: 3,
    title: "Buxoro",
    // icon: <LocationIcon />
  },
];

interface Props {
  onOpen?: boolean;
  onCancel?: () => void;
  show?: any;
}

const NewBranch: React.FC<Props> = ({ onOpen, onCancel }) => {
  return (
    <div
      className={styles.container}
      style={{ right: onOpen ? "0%" : "-100%" }}
    >
      <div className={styles.bottom} onClick={onCancel}></div>
      <div className={styles.newBranchBox}>
        <HeaderView
          title="Filial qo‘shish"
          onCancel={onCancel}
          icon={<CancelIcon />}
        />
        <div className={styles.formBox}>
          <div>
            <InputView
              inputSize="large"
              lable="Filial nomini kiriting"
              placeholder="Misol uchun: 100K"
              labelSize="17"
              icon={<BranchIcon />}
            />
            <TextView
              textSize="14"
              title="Ushbu filial nomi barchaga ko‘rinadi va u boshqa filial nomlariga o‘xshamasligi zarur Misol uchun: 100K va 2 filial nomi 100K Uchtepa"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
          <div className={styles.formItems}>
            <TextView title="Viloyat / Hudud" textSize="17" />
            <SelectInputView dataBase={data} icon={<LocationIcon />} />
            <TextView
              textSize="14"
              title="Filial joylashgan hudud"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
          <div className={styles.formItems}>
            <TextView title="Shahar / Tuman" textSize="17" />
            <SelectInputView dataBase={data} icon={<LocationIcon />} />
            <TextView
              textSize="14"
              title="Filial joylashgan shahar"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
          <div className={styles.formItems}>
            <InputView
              inputSize="large"
              lable="Telefon raqam"
              placeholder="+998 90 000-00-00"
              labelSize="17"
              icon={<MobileIcon />}
            />
            <TextView
              textSize="14"
              title="Ushbu telefon raqam sizning filial bilan bog’lanish uchun talab etiladi"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <ButtonView
            buttonSize="small"
            buttonType="primary"
            title="Tasdiqlash"
            icon={<CheckoutIcons />}
          />
          <ButtonView
            buttonSize="small"
            buttonType="primarySoft"
            title="Bekor qilish"
            style={{
              color: COLORS.bl500,
              marginRight: "10px",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NewBranch;
