import React, { useState } from "react";
import { CheckedIcons } from "../../assets/icons/IconsSvg";
import Checkout from "../Checkout/Checkout";
import styles from "./CourierCart.module.css";
type propsType = {
  clickMe?: any;
  icon?: any;
};
const CourierCartList = (props: propsType) => {
  const [clickMe, setClickMe] = useState(false);
  return (
    <div
      className={styles.container}
      style={{
        background: clickMe || props.clickMe ? " #EFE6FF" : "",
      }}
    >
      {props.icon ? (
        <div style={{ flex: 0.3 }} onClick={() => setClickMe((a) => !a)}>
          <Checkout clickMe={clickMe || props.clickMe} />
        </div>
      ) : null}

      <div style={{ flex: 1 }} className={styles.item}>
        <p>Javohir Karimov Tursunovich</p>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <p>+998 90 000-09-00</p>
      </div>
      <div style={{ flex: 1 }} className={styles.item}>
        <p>Toshkent shahar</p>
      </div>

      <div style={{ flex: 1 }} className={styles.item}>
        <p>12.09.2021</p>
      </div>
    </div>
  );
};

export default CourierCartList;
