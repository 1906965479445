// import React from "react";
// import styles from "./Draft.module.css";
// const Draft = () => {
//   return <div>Draft</div>;
// };

// export default Draft;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddPersonIcons,
  BottomNewIcons,
  ChromeIcons,
  DataIcons,
  DeleteIcon,
  LeftNewIcons,
  RightNewIcons,
  ShopIcons,
} from "../../../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../../../components/ButtonView/view";
import FilterList from "../../../../../../components/FilterList.tsx/FilterList";
import Hovered from "../../../../../../components/Hovered/Hovered";
import SortView from "../../../../../../components/SortView/SortView";
import { COLORS } from "../../../../../../constants/colors";
import styles from "./Draft.module.css";
const data = [
  {
    id: 0,
    title: "ID",
    flex: 0.5,
  },
  {
    id: 1,
    title: "Jo‘natma ",
    flex: 1,
  },
  {
    id: 2,
    title: "Kimdan/Kimga ",
    flex: 1,
  },
  {
    id: 3,
    title: "Qayerdan",
    flex: 1,
  },
  {
    id: 4,
    title: "Qayerga",
    flex: 1,
  },
  {
    id: 5,
    title: "Kuryer",
    flex: 1,
  },
  {
    id: 5,
    title: "Oldindan to‘lo‘v",
    flex: 1,
  },
];
const data3 = [
  {
    id: 0,
    icon: <DataIcons />,
    info: "Sana bo‘yicha",
  },
  {
    id: 1,
    icon: <ChromeIcons />,
    info: "Hudud",
  },
  {
    id: 2,
    icon: <ChromeIcons />,
    info: "Hudud",
  },
];
const ListModaldata = [
  {
    id: 0,
    info: "Holat",
  },
  {
    id: 1,
    info: "Skladni tanlang",
  },
  {
    id: 2,
    info: "Yuboruvchi",
  },
  {
    id: 3,
    info: "Haydovchi",
  },
  {
    id: 4,
    info: "Qayerdan",
  },
  {
    id: 4,
    info: "Qayerga",
  },
];
type PropsType = {
  setOpenModal?: any;
};
const Draft = ({ setOpenModal }: PropsType) => {
  const [clickMe, setClickMe] = useState(false);
  const [checkout, setCheckout] = useState(false);
  const navigation = useNavigate();
  return (
    <div className={styles.lists_box}>
      <div className={styles.Sort}>
        <SortView data={data3} ListModaldata={ListModaldata} />
      </div>
      <div className={styles.filter}>
        <FilterList
          setClickMe={setClickMe}
          clickMe={clickMe}
          icon={true}
          data={data}
        />
      </div>
      <div className={styles.lists}>
        {data?.map((item, index) => {
          return (
            <Hovered
              key={index}
              isAll={clickMe}
              icon={true}
              onClick={() => navigation("/mails-info")}
              setCheckout={setCheckout}
              checkout={checkout}
            />
          );
        })}
      </div>
      <div className={styles.foter}>
        <div className={styles.foter_left}>
          <ButtonView
            buttonSize={"large"}
            buttonType={"primary"}
            title={"Kuryerga biriktirish"}
            icon={<AddPersonIcons />}
            style={{ backgroundColor: COLORS.pr700, fontSize: "15px" }}
            clickMe={setOpenModal}
          />
          <ButtonView
            buttonSize={"small"}
            buttonType={"primary"}
            title={"O‘chirish"}
            icon={<DeleteIcon color={"#EE1E50"} />}
            style={{
              backgroundColor: COLORS.wh1000,
              fontSize: "15px",
              color: "#EE1E50",
              border: "1px solid #EE1E50",
              marginLeft: "20px",
            }}
          />
        </div>
        <div className={styles.foter_rigt}>
          <h4>Sahifalar tartibi</h4>
          <div className={styles.number_list}>
            <p>780 dan</p>
            <p>1-20</p>
            <div className={styles.bottom_icon}>
              <BottomNewIcons />
            </div>
          </div>
          <div className={styles.left_right}>
            <div className={styles.icon_box} style={{ marginRight: "20px" }}>
              <LeftNewIcons />
            </div>
            <p>1</p>
            <div className={styles.icon_box} style={{ marginLeft: "20px" }}>
              <RightNewIcons />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Draft;
