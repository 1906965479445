import { useNavigate } from 'react-router-dom';
import {
	ContactIcons,
	LeftIcons,
	LockIcons,
	LogoIcons,
} from '../../../assets/icons/IconsSvg';
import ButtonView from '../../../components/ButtonView/view';
import DropdownTr from '../../../components/DropdownTr/view';
import InputView from '../../../components/InputView/view';
import TextView from '../../../components/TextView/view';
import { ASSETS } from '../../../constants/imageRequire';
import { useContext } from 'react';
import styles from './style.module.css';
import AuthStateContext from '../../../services/all-state/AuthState';
import { observer } from 'mobx-react';
import { APP_ROUTES } from '../../../routes/Route';
import useRootStore from '../../../hooks/useRootStore';
import useRootState from '../../../hooks/useRootState';

const LoginView = observer(() => {
	const navigation = useNavigate();
	const store = useRootStore();
	const state = useRootState();

	const handleLogin = async () => {
		const res = await store.auth.login(state.auth.state.login);
		if (res) {
			navigation(APP_ROUTES.HOME, {
				replace: true,
				state: { isActive: true },
			});
			window.location.replace(`http://localhost:3000${APP_ROUTES.HOME}`);
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.leftBox}>
				<img className={styles.logincam} src={ASSETS.logincam} alt='' />
				<div className={styles.logo}>
					<LogoIcons />
				</div>
			</div>
			<div className={styles.rightBox}>
				<div className={styles.imgBox}>
					<DropdownTr
						style={{
							position: 'absolute',
							right: '25px',
							top: '25px',
						}}
					/>
					<img src={ASSETS.logintab} alt='' />
				</div>
				<div className={styles.form}>
					<InputView
						lable='Telefon raqam yoki E-mail kiriting'
						icon={<ContactIcons />}
						type='text'
						placeholder='example@gmail.com'
						onChange={(e) => state.auth.setLogin(e, 'username')}
						value={state.auth.state.login.username}
						inputSize='large'
					/>
					<InputView
						lable='Parolni kiriting'
						icon={<LockIcons />}
						type='text'
						placeholder='Shaxsiy kalit'
						onChange={(e) => state.auth.setLogin(e, 'password')}
						value={state.auth.state.login.password}
						style={{ marginTop: '25px' }}
						inputSize='large'
					/>
					<TextView
						onClick={() => navigation('/auth/login-new-pass')}
						style={{
							alignSelf: 'flex-end',
							fontSize: '14px',
							color: '#2F8AF5',
							cursor: 'pointer',
						}}
						title='Parolni unitdingizmi ?'
					/>
				</div>
				<div className={styles.bottomBox}>
					<ButtonView
						clickMe={handleLogin}
						icon={<LeftIcons />}
						title='Tizimga kirish'
						buttonSize={'large'}
						buttonType={'primary'}
					/>
					<div
						style={{
							display: 'flex',
							marginTop: '60px',
						}}>
						<TextView title='Menda shaxsiy sahifa mavjud emas' />
						<TextView
							onClick={() => navigation('/auth/sign-up')}
							style={{
								color: '#2F8AF5',
								cursor: 'pointer',
							}}
							title='Ro‘yhatdan o‘tish'
						/>
					</div>
				</div>
			</div>
		</div>
	);
});

export default LoginView;
