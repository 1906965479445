import React from "react";
import { AddIcons, DataIcons } from "../../../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../../../components/ButtonView/view";
import FilterList from "../../../../../../components/FilterList.tsx/FilterList";
import ListsCart from "../../../../../../components/ListsCart/ListsCart";
import SortView from "../../../../../../components/SortView/SortView";
import styles from "./style.module.css";

const data1 = [
  {
    id: 0,
    icon: <DataIcons />,
    info: "Sana bo‘yicha",
  },
];
const data = [
  {
    id: 0,
    title: "ID",
    flex: 0.5,
  },
  {
    id: 1,
    title: "Kimdan/Qayerdan",
    flex: 1,
  },
  {
    id: 2,
    title: "Kimga/Qayerga",
    flex: 1,
  },
  {
    id: 3,
    title: "Jo‘natma",
    flex: 1,
  },
  {
    id: 4,
    title: "Do‘kon",
    flex: 1,
  },
  {
    id: 5,
    title: "Sana",
    flex: 1,
  },
  {
    id: 5,
    title: "Status",
    flex: 1,
  },
];
const ListModaldata = [
  {
    id: 0,
    info: "Holat",
  },
  {
    id: 1,
    info: "Skladni tanlang",
  },
  {
    id: 2,
    info: "Yuboruvchi",
  },
  {
    id: 3,
    info: "Haydovchi",
  },
  {
    id: 4,
    info: "Qayerdan",
  },
  {
    id: 4,
    info: "Qayerga",
  },
];
type PropsType = {
  setOpenModal?: any;
};
const Mails = ({ setOpenModal }: PropsType) => {
  return (
    <div className={styles.content}>
      <div className={styles.sortBox}>
        <div className={styles.sort}>
          <SortView
            data={data1}
            ListModaldata={ListModaldata}
            cartSortStyle={{ fontSize: "12px" }}
          />
        </div>
        <ButtonView
          buttonSize="large"
          buttonType="primary"
          title="Yangi pochta qo‘shish"
          icon={<AddIcons />}
          style={{
            marginLeft: "10px",
            fontSize: "15px",
          }}
          clickMe={setOpenModal}
        />
      </div>
      <div style={{ marginTop: "10px" }}>
        <FilterList data={data} />
      </div>
      <div className={styles.listBox}>
        <ListsCart />
        <ListsCart />
        <ListsCart />
        <ListsCart />
        <ListsCart />
      </div>
    </div>
  );
};

export default Mails;
