import React from "react";
import {
  CarIcon,
  CheckoutIcons,
  Currency,
  DeleteIcon,
  HomeIcon,
  Insurance,
  LocationIcon,
  MobileIcon,
  SecondLocation,
  Sender,
  SuitCase,
} from "../../../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../../../components/ButtonView/view";
import InputView from "../../../../../../components/InputView/view";
import SelectInputView from "../../../../../../components/SelectInput/view";
import TextView from "../../../../../../components/TextView/view";
import { COLORS } from "../../../../../../constants/colors";
import styles from "./Main.module.css";
const data = [
  {
    id: 1,
    title: "Qoraqolpagiston respublikasi, Nukus shahar",
  },
  {
    id: 2,
    title: "Samarqand, Jomboy tuman",
  },
];
const Main = () => {
  return (
    <div className={styles.lists}>
      <div className={styles.content}>
        <div className={styles.formBox}>
          <div style={{ width: "90%" }}>
            <TextView title="Yuboruvchining ma’lumotlari" />
            <InputView
              inputSize="large"
              lable="To‘liq ism"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="Asror Qodirov"
              icon={<Sender />}
            />
            <InputView
              inputSize="large"
              lable="Telefon raqami"
              labelSize="16"
              placeholder="+998 33 333-09-00"
              style={{ marginTop: "30px" }}
              icon={<MobileIcon />}
            />
            <TextView
              textSize="16"
              style={{ marginTop: "30px" }}
              title="Qayerdan (Yuborish manzili)"
            />
            <SelectInputView dataBase={data} icon={<LocationIcon />} />
          </div>
        </div>
        <div className={styles.formBox}>
          <div style={{ width: "90%" }}>
            <TextView title="Qabul qiluvchining ma’lumotlari" />
            <InputView
              inputSize="large"
              lable="To‘liq ism"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="Javohir Karimov"
              icon={<Sender />}
            />
            <InputView
              inputSize="large"
              lable="Telefon raqami"
              labelSize="16"
              placeholder="+998 33 333-09-00"
              style={{ marginTop: "30px" }}
              icon={<MobileIcon />}
            />
            <TextView
              textSize="16"
              style={{ marginTop: "30px" }}
              title="Qayerdan (Yuborish manzili)"
            />
            <SelectInputView dataBase={data} icon={<SecondLocation />} />
          </div>
        </div>
        <div className={styles.formBox}>
          <div style={{ width: "90%" }}>
            <TextView title="Pochtaning ma’lumotlari" />
            <InputView
              inputSize="large"
              lable="Jo‘natma nomi"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="Avtomobil uchun ehtiyot qisim"
              icon={<SuitCase />}
            />
            <TextView
              textSize="16"
              style={{ marginTop: "30px" }}
              title="Trasport turi"
            />
            <SelectInputView dataBase={data} icon={<CarIcon />} />
            <TextView
              textSize="16"
              style={{ marginTop: "30px" }}
              title="Aniq manzil"
            />
            <SelectInputView dataBase={data} icon={<SecondLocation />} />
            <InputView
              inputSize="large"
              lable="Yetkazish summasi"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="34.000 uzs"
              icon={<Currency />}
            />
            <InputView
              inputSize="large"
              lable="Sug’urta summasi"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="14.500 uzs"
              icon={<Insurance />}
            />
            <InputView
              inputSize="large"
              lable="Umumiy summasi"
              labelSize="16"
              style={{ marginTop: "30px" }}
              placeholder="145.000 uzs"
              icon={<HomeIcon />}
            />
          </div>
        </div>
      </div>
      <div className={styles.footer}>
        <ButtonView
          buttonSize="small"
          buttonType="primary"
          title="Saqlash"
          icon={<CheckoutIcons />}
          style={{ backgroundColor: COLORS.sc700 }}
        />
        <span className={styles.deleteIcon}>{<DeleteIcon />}</span>
      </div>
    </div>
  );
};

export default Main;
