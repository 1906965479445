import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AddIcons, CloseIcons } from "../../../assets/icons/IconsSvg";
import { COLORS } from "../../../constants/colors";
import ButtonView from "../../ButtonView/view";
import ListItem from "../../ListItem/ListItem";
import styles from "./Navbar.module.css";
type PropsType = {
  data?: any;
  title?: string;
  info?: any;
  button_title?: any;
  typeMain?: any;
  setActive?: any;
};

const NavbarMail = (props: PropsType) => {
  const [clickMe, setClickMe] = useState("Yangi zakazlar");
  const navigation = useNavigate();

  const onClivj = ({ title }: { title: string }) => {
    setClickMe(title);
    props?.setActive(title);
  };
  return (
    <div className={styles.navbar}>
      <div
        className={styles.left_container}
        style={{ justifyContent: props.data ? "space-between" : "center" }}
      >
        <div className={styles.title_box}>
          <h1>{props.title}</h1>
          {props.info ? <p>{props.info}</p> : null}
        </div>
        {props.data ? (
          <div className={styles.list_box_div}>
            <ul className={styles.list_box}>
              {props.data?.map((item: any, index: number) => {
                return (
                  <ListItem
                    key={index}
                    title={item.title}
                    setClickMe={onClivj}
                    clickMe={clickMe}
                    color={item.color}
                  />
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
      <div className={styles.right_container}>
        <ButtonView
          buttonSize={"large"}
          buttonType={"primary"}
          style={{ background: "#3FDA7D", border: "none" }}
          title="Pochta qo‘shish"
          icon={<AddIcons />}
          clickMe={() => navigation("/add-mail")}
        />
        {props.typeMain ? (
          <ButtonView
            buttonSize={"small"}
            buttonType={"primary"}
            style={{
              background: COLORS.wh1000,
              border: "1px solid #EE1E50",
              color: "red",
              marginLeft: "20px",
            }}
            title="Bekor qilindi"
            icon={<CloseIcons />}
          />
        ) : null}
      </div>
    </div>
  );
};

export default NavbarMail;
