import React, { useState } from "react";
import {
  CancelIcon,
  CheckedIcons,
  CheckoutIcons,
  LinksInternetIcons,
  QuestionIcons,
} from "../../../assets/icons/IconsSvg";

import ButtonView from "../../ButtonView/view";
import Checkout from "../../Checkout/Checkout";
import HeaderView from "../../Header/view";
import InputView from "../../InputView/view";
import styles from "./Settings.module.css";
interface Props {
  onOpen?: boolean;
  onCancel?: () => void;
  show?: any;
  setNoteOpen?: any;
}
const data = [
  {
    id: 1,
    title: "Yangi zakazlar qo‘shilsa",
  },
  {
    id: 2,
    title: "Kuryer o‘zgartirilsa",
  },
  {
    id: 3,
    title: "Bekor qiligan holatda",
  },
];
const SettingsModule = ({ onOpen, onCancel, setNoteOpen }: Props) => {
  const [checkout, setCheckout] = useState("");
  return (
    <div
      className={styles.container}
      style={{ right: onOpen ? "0%" : "-100%" }}
    >
      <div className={styles.bottom} onClick={onCancel}></div>
      <div className={styles.newBranchBox}>
        <HeaderView
          title="Filial bildirishnomalarini sozlang"
          onCancel={onCancel}
          icon={<CancelIcon />}
        />
        <div className={styles.lists}>
          <div className={styles.links}>
            <InputView
              inputSize={"large"}
              lable="Website linkini kiriting"
              labelSize="22"
              icon={<LinksInternetIcons />}
              placeholder="Misol uchun: www.100k.uz"
              rightIcon={<QuestionIcons />}
            />
          </div>
          <div className={styles.detas}>
            <h4>Bildirishnomalar qachon kelsin ?</h4>
            {data?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.itemCart}
                  onClick={() => setCheckout(item.title)}
                  style={{
                    backgroundColor: checkout === item.title ? "#EFE6FF" : "",
                    borderColor: checkout === item.title ? "#BA95F7" : "",
                  }}
                >
                  <div className={styles.chekout}>
                    {checkout === item.title ? <CheckedIcons /> : <div></div>}
                  </div>

                  <p>{item.title}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className={styles.footer}>
          <ButtonView
            buttonSize="small"
            buttonType="primary"
            title="Tasdiqlash"
            icon={<CheckoutIcons />}
            clickMe={() => setNoteOpen((a: any) => !a)}
          />
        </div>
      </div>
    </div>
  );
};

export default SettingsModule;
