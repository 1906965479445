import React, { useState } from "react";
import {
  ChromeIcons,
  DataIcons,
  SettingIcons,
  StatusIcons,
} from "../../../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../../../components/ButtonView/view";
import ListCart from "../../../../../../components/CourierCollectionCart/ListCart/ListCart";
import FilterList from "../../../../../../components/FilterList.tsx/FilterList";
import SettingsModule from "../../../../../../components/SortModals/100Kmodule/SettingsModule";
import SortView from "../../../../../../components/SortView/SortView";
import ExpressList from "../ExpressList/ExpressList";
import NoteLIst from "./NoteList/NoteLIst";
import NoteModule from "./NoteModule/NoteModule";
import styles from "./Notifications.module.css";
const data = [
  {
    id: 0,
    title: "Websayt linki",
    flex: "",
  },
  {
    id: 1,
    title: "Holat",
    flex: "",
  },
  {
    id: 2,
    title: "Habar soni",
    flex: "",
  },
  {
    id: 3,
    title: "Vaqt",
    flex: "",
  },
  {
    id: 4,
    title: "Batafil",
    flex: "0.7",
  },
];
const data2 = [
  {
    id: 0,
    icon: <DataIcons />,
    info: "Sana bo‘yicha",
  },
  {
    id: 1,
    icon: <StatusIcons />,
    info: "Hudud",
  },
];
const Notifications = () => {
  const [express, setExpress] = useState(false);
  const [noteOpen, setNoteOpen] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.noActive}>
        <h4>Sizda hozircha bildirishnomalar mavjud emas 😐 </h4>
        <ButtonView
          buttonSize={"small"}
          buttonType={"primary"}
          icon={<SettingIcons />}
          title="Sozlash"
          clickMe={() => setExpress((a) => !a)}
        />
      </div>
      {/* <div className={styles.lists_box}>
        <div className={styles.sort}>
          <SortView data={data2} />
          <ButtonView
            buttonSize={"large"}
            buttonType={"primary"}
            icon={<SettingIcons />}
            title="Sozlash"
          />
        </div>
        <div className={styles.filter}>
          <FilterList data={data} />
        </div>
        <div className={styles.lists}>
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
          <NoteLIst />
        </div>
      </div> */}
      <SettingsModule
        onCancel={() => setExpress(false)}
        onOpen={express}
        setNoteOpen={setNoteOpen}
      />
      <NoteModule onOpen={noteOpen} onCancel={() => setNoteOpen(false)} />
    </div>
  );
};

export default Notifications;
