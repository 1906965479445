import { SignUpResponse } from '../../api/requests/api.account.types';
import { makeAutoObservable } from 'mobx';
import api from '../../api';
import tokens from '../../api/tokens';
import { LoginPayload, LoginResponse, SignUpPayload } from '../../api/requests';
import { Operation } from '../../utils/Operation';
import { AuthResponseMessage } from '../../types/Auth.types';

export const CONFIG_KEY = 'config';
export const CONFIG_VALUES = {
	isEmailConfirm: 'isEmailConfirm',
};

export default class AuthStore {
	loginOperation = new Operation<LoginResponse>({} as LoginResponse);
	signUpOperation = new Operation<SignUpResponse>({} as SignUpResponse);
	logoutOperation = new Operation(undefined);

	shouldClearData = false;

	constructor() {
		makeAutoObservable(this);
	}

	signUp = (payload: SignUpPayload) => {
		this.signUpOperation.run(() => api.signUp(payload));
		console.log('this.signUpOperation', this.signUpOperation.data.message);
		if (this.signUpOperation.isSuccess) {
			const { data } = this.signUpOperation;
			if (data.message === AuthResponseMessage.SignUpSuccess) {
				this.saveTokens(data.data);
			}
		}
	};

	login = async (payload: LoginPayload) => {
		this.clear();
		await this.loginOperation.run(() => api.login(payload));
		const {
			data: { data, message },
		} = this.loginOperation;
		if (message === AuthResponseMessage.LoginSuccess) {
			this.saveTokens(data);
		}
		return this.loginOperation.isSuccess;
	};

	logout = async (): Promise<void> => {
		this.shouldClearData = true;
		if (api.hasAuthorizationHeader()) {
			// not api logout with token clear and mobx clear
			api.clearAccessToken();
		}
		tokens.clear();
	};

	saveTokens = (accessToken: string) => {
		tokens.saveTokens(accessToken);
	};

	clearData = async () => {
		if (this.shouldClearData) {
			this.clear();
		}
	};

	clear = () => {
		this.signUpOperation.clear();
		this.loginOperation.clear();
		this.logoutOperation.clear();
		this.shouldClearData = false;
	};
}
