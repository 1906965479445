import {
  CourierIcons,
  DashboardIcons,
  EnterIcons,
  ExitIcons,
  MailIcons,
} from "../../assets/icons/IconsSvg";

export const SidebarData = [
  {
    title: "Pochtalar",
    path: "/mails",
    icon: <MailIcons />,
    cName: "nav-text",
  },
  {
    title: "Kirim",
    path: "/enter",
    icon: <EnterIcons />,
    cName: "nav-text",
  },
  {
    title: "Chiqim",
    path: "/output",
    icon: <ExitIcons color="white" />,
    cName: "nav-text",
  },
  {
    title: "Kuryerlar",
    path: "/couriers",
    icon: <CourierIcons />,
    cName: "nav-text",
  },
  {
    title: "Hisob kitoblar",
    path: "/calculations",
    cName: "nav-text",
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcons />,
    cName: "nav-text",
  },
];
