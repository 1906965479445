import React from 'react'
import styles from "./style.module.css"

interface Props {
    time?: any;
    text?: any;
    author?: any;
    listData?: any;
}

const CaseHistoryList: React.FC<Props> = ({
    time,
    text,
    author,
    listData
}) => {
    return (
        <>
            {
                listData.map((e: any, index: any) => {
                    return (
                        <div className={styles.container} key={index}>
                            <div className={styles.listCart}>
                                <div className={styles.listItemOne}>
                                    <p>{e.time}</p>
                                </div>
                                <div className={styles.listItem}>
                                    <div className={styles.listItemBtn}>
                                        <p>{e.text}</p>
                                    </div>
                                </div>
                                <div className={styles.listItem}>
                                    <h3>{e.author}</h3>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default CaseHistoryList;