import React, { useState } from "react";
import { CancelIcon, CheckoutIcons } from "../../assets/icons/IconsSvg";
import { COLORS } from "../../constants/colors";
import ButtonView from "../ButtonView/view";
import CourierCart from "../CourierCollectionCart/CourierCart";
import HeaderView from "../Header/view";
import SearchView from "../SearchView/SearchView";
import ListModalView from "../SortModals/ListModal/ListModalView";
import styles from "./style.module.css";

const data = [
  {
    id: 1,
    info: "Ismi A-Z",
  },
  {
    id: 2,
    info: "ID bo‘yicha",
  },
];

interface Props {
  onOpen?: boolean;
  onCancel?: () => void;
}

const CourierAttech: React.FC<Props> = ({ onOpen, onCancel }) => {
  const [onClick, setOnClick] = useState();
  return (
    <>
      <div className={`${styles.container} ${onOpen && styles.active}`}>
        <div className={styles.overBox} onClick={onCancel}></div>
        <div className={styles.content}>
          <div className={styles.headerBox}>
            <HeaderView
              title="Kuryer Biriktirish"
              icon={<CancelIcon />}
              onCancel={onCancel}
            />
            <div className={styles.searchBox}>
              <SearchView setOnClick={setOnClick} />
              <ListModalView
                title="Reyting bo‘yicha"
                ListModaldata={data}
                onClick={onClick}
              />
            </div>
          </div>
          <div className={styles.listBox}>
            <CourierCart />
            <CourierCart />
            <CourierCart />
            <CourierCart />
            <CourierCart />
          </div>
          <div className={styles.footer}>
            <ButtonView
              buttonSize="small"
              buttonType="primary"
              title="Tasdiqlash"
              icon={<CheckoutIcons />}
            />
            <ButtonView
              buttonSize="small"
              buttonType="primarySoft"
              title="Bekor qilish"
              style={{
                color: COLORS.bl500,
                marginRight: "10px",
              }}
            />
          </div>
        </div>
      </div>
      <div className={styles.listBox}>
        <CourierCart />
        <CourierCart />
        <CourierCart />
        <CourierCart />
        <CourierCart />
      </div>
      <div className={styles.footer}>
        <ButtonView
          buttonSize="small"
          buttonType="primary"
          title="Tasdiqlash"
          icon={<CheckoutIcons />}
        />
        <ButtonView
          buttonSize="small"
          buttonType="primarySoft"
          title="Bekor qilish"
          style={{
            color: COLORS.bl500,
            marginRight: "10px",
          }}
        />
      </div>
    </>
  );
};

export default CourierAttech;
