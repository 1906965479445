import { BottomIcons, TopIcons } from "../../assets/icons/IconsSvg";
import Checkout from "../Checkout/Checkout";
import styles from "./FilterList.module.css";
type propsType = {
  clickMe?: any;
  setClickMe?: any;
  icon?: any;
  text1?: any;
  data?: any;
};
const FilterList = (props: propsType) => {
  return (
    <div className={styles.container} style={{ flex: 1 }}>
      {props.icon ? (
        <div
          style={{ marginRight: "30px" }}
          onClick={() => props.setClickMe((a: any) => !a)}
        >
          <Checkout clickMe={props.clickMe} />
        </div>
      ) : null}
      {props.data?.map((item: any, index: number) => {
        return (
          <div
            key={index}
            style={{ flex: item.flex ? item.flex : 1 }}
            className={styles.item}
          >
            <p>{item.title}</p>
            <div className={styles.icons}>
              <TopIcons />
              <BottomIcons />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FilterList;
