import React, { useEffect, useState } from "react";

import FilterList from "../../../../components/FilterList.tsx/FilterList";

import styles from "./Courier.module.css";

import CourierCartList from "../../../../components/CourierCartList/CourierCartList";
import NavbarCourier from "../../../../components/Navbar/CourierNavbar/NavbarCourier";
import AddCourier from "../../../../components/AddCourier/AddCourier";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import ButtonView from "../../../../components/ButtonView/view";
import TextView from "../../../../components/TextView/view";
import { COLORS } from "../../../../constants/colors";
import { AddIcons, SearchIcons } from "../../../../assets/icons/IconsSvg";
const data = [
  {
    id: 0,
    title: "I.F.O",
    flex: 1,
  },
  {
    id: 1,
    title: "Telefon raqam",
    flex: 1,
  },
  {
    id: 2,
    title: "Hudud",
    flex: 1,
  },
  {
    id: 3,
    title: "Filialda ishlaydi",
    flex: 1,
  },
];

const CourierView = () => {
  const [clickMe, setClickMe] = useState(false);
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <TextView
          title="Kuryerlar"
          textSize="30"
          style={{ color: COLORS.bl1000 }}
        />
        <div className={styles.rightContainer}>
          <div className={styles.search}>
            <SearchIcons />
            <input className={styles.input} placeholder="Qdiruv" />
          </div>
          <ButtonView
            buttonSize={"large"}
            buttonType={"primary"}
            title="Kuryer qo‘shish"
            icon={<AddIcons />}
            clickMe={() => setShow((e) => !e)}
          />
        </div>
      </div>
      <AddCourier onOpen={show} onCancel={() => setShow((e) => !e)} />
      <div className={styles.lists_box}>
        <div className={styles.filter}>
          <FilterList
            setClickMe={setClickMe}
            clickMe={clickMe}
            icon={false}
            data={data}
          />
        </div>
        <div className={styles.lists}>
          <CourierCartList />
        </div>
      </div>
    </div>
  );
};

export default CourierView;
