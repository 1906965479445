import React from "react";
import {
  BranchIcon,
  CheckoutIcons,
  CloudeIcons,
  DownloadImageIcons,
  LocationIcon,
  MobileIcon,
  PercentageIcons,
  WalletIcons,
} from "../../../../../../assets/icons/IconsSvg";
import ButtonView from "../../../../../../components/ButtonView/view";
import InputView from "../../../../../../components/InputView/view";
import SelectInputView from "../../../../../../components/SelectInput/view";
import TextView from "../../../../../../components/TextView/view";
import { COLORS } from "../../../../../../constants/colors";
import ExpresCart from "../ExpresCart/ExpresCart";
import styles from "./Settings.module.css";
const data2 = [
  {
    id: 1,
    title: "Toshkent",
  },
  {
    id: 2,
    title: "Samarqand",
  },
  {
    id: 3,
    title: "Buxoro",
  },
];
const Settings = () => {
  return (
    <div className={styles.lists_box}>
      <div className={styles.lists}>
        <div className={styles.wallet_percentage}>
          <div className={styles.wallet}>
            <ExpresCart
              icon={<WalletIcons width="52" height="45" fill="#3FDA7D" />}
              title="34.000.000 uzs"
              info={"Filial balansi"}
              cartBoxStyle={{ backgroundColor: "#3FDA7D" }}
              iconStyle={{ backgroundColor: COLORS.wh1000 }}
            />
          </div>
          <div className={styles.percentage}>
            <ExpresCart
              icon={<PercentageIcons />}
              title="15%"
              info={"Filial komissiyasi"}
              cartBoxStyle={{ backgroundColor: " #7638DB" }}
              iconStyle={{ backgroundColor: COLORS.wh1000 }}
            />
          </div>
        </div>
        {/* table_box */}
        <div className={styles.table_box}>
          <div>
            <InputView
              inputSize="large"
              lable="Sklad nomi"
              placeholder="100K Express"
              labelSize="17"
              icon={<BranchIcon />}
            />
            <TextView
              textSize="14"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
          <div className={styles.formItems}>
            <TextView title="Viloyat / Hudud" textSize="17" />
            <SelectInputView dataBase={data2} icon={<LocationIcon />} />
            <TextView
              textSize="14"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
          <div className={styles.formItems}>
            <TextView title="Shahar / Tuman" textSize="17" />
            <SelectInputView dataBase={data2} icon={<LocationIcon />} />
            <TextView
              textSize="14"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
          <div className={styles.formItems}>
            <InputView
              inputSize="large"
              lable="Telefon raqam"
              placeholder="+998 90 000-00-00"
              labelSize="17"
              icon={<MobileIcon />}
            />
            <TextView
              textSize="14"
              style={{
                color: COLORS.bl400,
                fontFamily: "inter6",
                fontWeight: "200",
              }}
            />
          </div>
        </div>
        {/* table_box */}
        <div className={styles.add_image_box}>
          <div className={styles.info_cart_btn}>
            <div className={styles.info_cart}>
              <p className={styles.cart_item_one}>
                Rasmning hajmi <small>15Mb</small> dan oshmasligi kerak
              </p>
              <p className={styles.cart_item_two}>
                Rasming o‘lchami <small>1.000 x 1.000</small> bo‘lishi zarur
              </p>
            </div>
            <div className={styles.info_btn}>
              <ButtonView
                buttonSize={"large"}
                buttonType={"primary"}
                title="Rasm yuklash"
                icon={<CloudeIcons />}
              />
            </div>
          </div>
          <div className={styles.add_image}>
            <div>
              <DownloadImageIcons />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.foter}>
        <ButtonView
          buttonSize={"large"}
          buttonType={"primary"}
          title="O‘zgarisharni saqlash"
          icon={<CheckoutIcons />}
          style={{ backgroundColor: COLORS.pr700 }}
        />
      </div>
    </div>
  );
};

export default Settings;
