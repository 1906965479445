import { makeAutoObservable } from 'mobx';
import React, { createContext } from 'react';

// @types
type Login = {
	username: string;
	password: string;
};

type Event = React.FormEvent<HTMLInputElement>;

type LoginNewPassword = {
	email: string;
	password: string;
	passwordConfirm: string;
};

type SignUp = {
	phone: string;
	password: string;
	name: 'Shahzod';
};

type SignUpCode = {
	code: string;
};

export default class AuthState {
	private login: Login = {
		username: '',
		password: '',
	};
	private loginNewPassword: LoginNewPassword = {
		email: '',
		password: '',
		passwordConfirm: '',
	};
	private signUp: SignUp = {
		phone: '',
		password: '',
		name: 'Shahzod',
	};
	private signUpCode: SignUpCode = {
		code: '',
	};

	constructor() {
		makeAutoObservable(this);
	}

	private onChangeInput = (e: Event) => e.currentTarget.value;

	setLogin = (e: Event, key: keyof typeof this.login) => {
		this.login = { ...this.login, [key]: this.onChangeInput(e) };
	};

	setLoginNewPassword = (
		e: Event,
		key: keyof typeof this.loginNewPassword
	) => {
		this.loginNewPassword = {
			...this.loginNewPassword,
			[key]: this.onChangeInput(e),
		};
	};

	setSignUp = (e: Event, key: keyof typeof this.signUp) => {
		this.signUp = { ...this.signUp, [key]: this.onChangeInput(e) };
	};

	setSignUpCode = (e: Event, key: keyof typeof this.signUpCode) => {
		this.signUpCode = { ...this.signUpCode, [key]: this.onChangeInput(e) };
	};

	get state() {
		return {
			login: this.login,
			loginNewPassword: this.loginNewPassword,
			signUp: this.signUp,
			signUpCode: this.signUpCode,
		};
	}
}
