import { useState } from "react";

import NavbarMail from "../../../../components/Navbar/MailNavbar/NavbarMail";
import Draft from "./components/Draft/Draft";
import NewOrders from "./components/NewOrders/NewOrders";
import styles from "./MailView.module.css";
import CourierAttech from "../../../../components/CourierAttech/CourierAttech";
import useVisible from "../../../../hooks/useVisible";

const data2 = [
  {
    id: 0,
    title: "Yangi zakazlar",
  },
  {
    id: 1,
    title: "Skladga qabul qilinganlar",
  },
  {
    id: 2,
    title: "Yo‘ldagilar",
  },
  {
    id: 3,
    title: "Yetkazib berilganlar",
  },

  {
    id: 5,
    title: "Qoralama*",
    color: "red",
  },
];

const MailView = () => {
  const [active, setActive] = useState("");

  let saidbar = "Yangi zakazlar";

  if (active === "Yangi zakazlar") {
    saidbar = "Yangi zakazlar";
  } else if (active === "Skladga qabul qilinganlar") {
    saidbar = "Skladga qabul qilinganlar";
  } else if (active === "Yo‘ldagilar") {
    saidbar = "Yo‘ldagilar";
  } else if (active === "Yetkazib berilganlar") {
    saidbar = "Yetkazib berilganlar";
  } else if (active === "Qoralama*") {
    saidbar = "Qoralama*";
  }
  const visebil = useVisible();
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <NavbarMail data={data2} title="Pochtalar" setActive={setActive} />
      </div>
      {saidbar == "Yangi zakazlar" ? <NewOrders /> : null}
      {saidbar == "Skladga qabul qilinganlar" ? <NewOrders /> : null}
      {saidbar == "Yo‘ldagilar" ? <NewOrders /> : null}
      {saidbar == "Yetkazib berilganlar" ? <NewOrders /> : null}
      {saidbar == "Qoralama*" ? <Draft setOpenModal={visebil.show} /> : null}
      <CourierAttech onCancel={visebil.hide} onOpen={visebil.visible} />
    </div>
  );
};

export default MailView;
