
const VALUES = {
  ACCESS_TOKEN: 'accessToken',
   isAuthorization : false
} as const;

const tokens = {
 // save tokens to local storage
  saveTokens: (accessToken: string) => {
    localStorage.setItem(VALUES.ACCESS_TOKEN, accessToken);
  },
  // get tokens from local storage
  getAccessToken: () => {
    return localStorage.getItem(VALUES.ACCESS_TOKEN);
  } ,
  // remove tokens from local storage
  clear: () => {
    localStorage.removeItem(VALUES.ACCESS_TOKEN);
  }
};

export default tokens;
