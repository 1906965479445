import React, { CSSProperties } from "react";
import styles from "./style.module.css";
interface Props {
  title?: string;
  icon?: any;
  clickMe?: () => void;
  style?: CSSProperties;
  titleStyle?: CSSProperties;
  disabled?: boolean;
  buttonSize: "large" | "small";
  buttonType: "primary" | "primaryOutline" | "primarySoft";
}

const ButtonView: React.FC<Props> = ({
  title,
  icon,
  clickMe,
  disabled,
  style,
  titleStyle,
  buttonSize,
  buttonType,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={clickMe}
      className={`${styles[buttonSize]} ${styles[buttonType]}`}
      style={{
        ...style,
      }}
    >
      {icon ? <div className={styles.icon}>{icon}</div> : <span></span>}
      <p style={{ ...titleStyle }}>{title}</p>
    </button>
  );
};

export default ButtonView;
