import React, { useState } from "react";
import {
  BottomNewIcons,
  ChromeIcons,
  DataIcons,
  LeftNewIcons,
  RightNewIcons,
  ShopIcons,
} from "../../../../assets/icons/IconsSvg";

import FilterList from "../../../../components/FilterList.tsx/FilterList";

import SortView from "../../../../components/SortView/SortView";

import styles from "./Output.module.css";

import ListsCart from "../../../../components/ListsCart/ListsCart";
import NavbarEnter from "../../../../components/Navbar/ErnterNavbar/NavbarEnter";
const data = [
  {
    id: 0,
    title: "ID",
    flex: 0.5,
  },
  {
    id: 1,
    title: "Vaqt",
    flex: 1,
  },
  {
    id: 2,
    title: "Do‘kon",
    flex: 1,
  },
  {
    id: 3,
    title: "Hudud",
    flex: 1,
  },
  {
    id: 4,
    title: "Sklad",
    flex: 1,
  },
  {
    id: 5,
    title: "Status",
    flex: 1,
  },
  {
    id: 5,
    title: "Pochta soni",
    flex: 1,
  },
];

const data2 = [
  {
    id: 0,
    icon: <DataIcons />,
    info: "Sana bo‘yicha",
  },
  {
    id: 1,
    icon: <ShopIcons color={"#2B2B2B"} />,
    info: "Do‘kon",
  },
];
const data3 = [
  {
    id: 0,
    info: "Yangi",
  },
  {
    id: 1,
    info: "Bekor qilingan",
  },
  {
    id: 2,
    info: "Yo‘lda",
  },
  {
    id: 3,
    info: "O‘chirilgan",
  },
];
const OutputView = () => {
  const [clickMe, setClickMe] = useState(false);
  return (
    <div className={styles.container}>
      <div className={styles.navbar}>
        <NavbarEnter title="Do‘kon listi" data2={data3} />
      </div>

      <div className={styles.lists_box}>
        <div className={styles.Sort}>
          <SortView data={data2} />
        </div>
        <div className={styles.filter}>
          <FilterList setClickMe={setClickMe} clickMe={clickMe} data={data} />
        </div>
        <div className={styles.lists}>
          <ListsCart />
          <ListsCart />
          <ListsCart />
          <ListsCart />
          <ListsCart />
          <ListsCart />
          <ListsCart />
        </div>
        <div className={styles.foter}>
          <div className={styles.foter_rigt}>
            <h4>Sahifalar tartibi</h4>
            <div className={styles.number_list}>
              <p>780 dan</p>
              <p>1-20</p>
              <div className={styles.bottom_icon}>
                <BottomNewIcons />
              </div>
            </div>
            <div className={styles.left_right}>
              <div className={styles.icon_box} style={{ marginRight: "20px" }}>
                <LeftNewIcons />
              </div>
              <p>1</p>
              <div className={styles.icon_box} style={{ marginLeft: "20px" }}>
                <RightNewIcons />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutputView;
