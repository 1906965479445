import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginView from "../screens/Auth/LoginIn/view";
import LoginNewPass from "../screens/Auth/SignInNewPass/loginNewPass";
import SignUp from "../screens/Auth/SignUp/view";
import SignUpGetCode from "../screens/Auth/SignUpGetCode/signUpGetCode";
import Auth from "../screens/Auth/View";
import HomeView from "../screens/Home/view";

import ExpressView from "../screens/Home/views/100KExpress/ExpressView";
import AddMail from "../screens/Home/views/AddMail/AddMail";
import AddedMail from "../screens/Home/views/AddedMail/AddedMail";
import CalculationsView from "../screens/Home/views/CalculationsView/CalculationsView";
import CourierView from "../screens/Home/views/Courier/CourierView";
import DashboardView from "../screens/Home/views/Dashboard/DashboardView";
import EnterView from "../screens/Home/views/EnterView/EnterView";
import InEnter from "../screens/Home/views/InEnter/InEnter";
import MailsInfoView from "../screens/Home/views/MailsInfoView/MailsInfoView";
import MailView from "../screens/Home/views/MailsView/MailView";
import OutputView from "../screens/Home/views/Output/OutputView";
import { APP_ROUTES } from "./Route";
import ProtectedRoute from "./ProtectedRoute";
const RoutesView = () => {



  return (
    <BrowserRouter>
      <Routes>
        <Route path={APP_ROUTES.AUTH} element={
          <ProtectedRoute><Auth /></ProtectedRoute>}>
          <Route path={APP_ROUTES.LOGIN} element={<LoginView />} />
          <Route path={APP_ROUTES.LOGIN_NEW_PASS} element={<LoginNewPass />} />
          <Route path={APP_ROUTES.REGISTER} element={<SignUp />} />
          <Route path={APP_ROUTES.SIGN_UP_GET_CODE} element={<SignUpGetCode />} />
        </Route>
        <Route path="/" element={
          <ProtectedRoute>
            <HomeView />
          </ProtectedRoute>}
        >
          <Route path="/mails" element={<MailView />} />
          <Route path="/couriers" element={<CourierView />} />
          <Route path="/enter" element={<EnterView />} />
          <Route path="/output" element={<OutputView />} />
          <Route path="/calculations" element={<CalculationsView />} />
          <Route path="/dashboard" element={<DashboardView />} />
          <Route path="/100k" element={<ExpressView />} />
          <Route path="/mails-info" element={<MailsInfoView />} />
          <Route path="/add-mail" element={<AddMail />} />
          <Route path="/added-mail" element={<AddedMail />} />
          <Route path="/in-enter" element={<InEnter />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesView;
