import React from "react";
import styles from "./listItem.module.css";

type typeProps = {
  title?: string;
  number?: number;
  setClickMe?: any;
  clickMe?: any;
  color?: any;
};

const ListItem = (props: typeProps) => {
  return (
    <div
      key={props.title}
      className={
        props.clickMe === props.title
          ? `${styles.container} ${styles.active}`
          : `${styles.container}`
      }
      onClick={() => props.setClickMe({ title: props.title })}
    >
      <h4
        className={
          props.clickMe === props.title
            ? `${styles.title} ${styles.title_active}`
            : `${styles.title}`
        }
        style={{ color: props.color }}
      >
        {props.title}
      </h4>
      {props.number ? (
        <div className={styles.number_box}>
          <p className={styles.number}>{props.number ? props.number : null}</p>
        </div>
      ) : null}
    </div>
  );
};

export default ListItem;
