import React from "react";
import {
  ExclamationIcons,
  LockIcons,
  QuestionIcons,
} from "../../../../../../../assets/icons/IconsSvg";
import InputView from "../../../../../../../components/InputView/view";
import styles from "./Note.module.css";
type PropsType = {
  onCancel?: any;
  onOpen?: any;
};
const NoteModule = ({ onCancel, onOpen }: PropsType) => {
  return (
    <div
      className={styles.container}
      style={{ display: onOpen ? "flex" : "none" }}
    >
      <div className={styles.bottom} onClick={onCancel}></div>
      <div className={styles.newBranchBox}>
        <div className={styles.icons}>
          <ExclamationIcons />
        </div>
        <h4>Siz ushbu kalitga masul shaxsiz uni ishonchli saqlang !</h4>
        <div className={styles.input}>
          <InputView
            inputSize={"large"}
            icon={<LockIcons />}
            rightIcon={<QuestionIcons />}
            lable="Kalit"
          />
        </div>
      </div>
    </div>
  );
};

export default NoteModule;
