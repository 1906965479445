import React from "react";
import { CheckedIcons } from "../../assets/icons/IconsSvg";
import styles from "./checkout.module.css";
type propsType = {
  clickMe?: any;
};
const Checkout = ({ clickMe }: propsType) => {
  return (
    <div
      className={styles.checkout}
      style={{ border: clickMe ? "0" : "2px solid black" }}
    >
      {clickMe ? <CheckedIcons /> : null}
    </div>
  );
};

export default Checkout;
