import React, { useCallback, useEffect, useState } from "react";
import { CheckedIcons } from "../../assets/icons/IconsSvg";
import Checkout from "../Checkout/Checkout";
import styles from "./Hovered.module.css";
type propsType = {
  isAll?: boolean;
  icon?: any;
  onClick?: () => void;
  setCheckout?: any;
  checkout?: any;
  onChange?(visible: boolean): void;
  value?: boolean;
};
const Hovered = (props: propsType) => {
  const [isVisible, setIsVisible] = useState(props.value || false);

  const checkoutHandler = useCallback(() => {
    const newValue = !isVisible;
    setIsVisible(newValue);
    props.onChange?.(newValue);
  }, [isVisible]);

  useEffect(() => {
    setIsVisible(!!props.isAll);
  }, [props.isAll]);

  useEffect(() => {
    setIsVisible(!!props.value);
  }, [props.value]);

  return (
    <div
      className={styles.mail_box}
      style={{
        background: isVisible ? " #EFE6FF" : "",
      }}
    >
      <div className={styles.icons}>
        {props.icon ? (
          <div
            onClick={() => {
              checkoutHandler();
            }}
          >
            <Checkout clickMe={isVisible} />
          </div>
        ) : null}
      </div>

      <div className={styles.lists_cart} onClick={props.onClick}>
        <div style={{ flex: 0.5 }} className={styles.one_box}>
          <p>7709</p>
        </div>
        <div style={{ flex: 1 }} className={styles.two_box}>
          <p>Redmi AirDots 2</p>
        </div>
        <div style={{ flex: 1 }}>
          <div className={styles.box_name}>
            <h4>Gafur</h4>
            <p>+998 90 000-90-08</p>
          </div>
          <div className={styles.box_name}>
            <h4>Elyor</h4>
            <p>+998 90 000-90-08</p>
          </div>
        </div>
        <div style={{ flex: 1 }}>
          <p>Toshkent shah, </p>
          <p>Chilonzor tum</p>
        </div>
        <div style={{ flex: 1 }}>
          <p>Samarqand vil, </p>
          <p>Urgut tum</p>
        </div>
        <div style={{ flex: 1 }}>
          <div className={styles.box_name}>
            <h4>Habibulloh</h4>
          </div>
          <div className={styles.sity_name}>
            <p>+998 90 000-55-44</p>
          </div>
        </div>
        <div style={{ flex: 1 }} className={styles.last_box}>
          <h4>Yo‘q</h4>
        </div>
      </div>
    </div>
  );
};

export default Hovered;