import React from "react";
import { useNavigate } from "react-router-dom";
import ButtonView from "../../../components/ButtonView/view";
import InputView from "../../../components/InputView/view";
import TextView from "../../../components/TextView/view";
import { ASSETS } from "../../../constants/imageRequire";
import styles from "./style.module.css";
import ReactInputVerificationCode from "react-input-verification-code";
import styled from "styled-components";
import { LeftIcons } from "../../../assets/icons/IconsSvg";
import DropdownTr from "../../../components/DropdownTr/view";

const CustomStylesContainer = styled.div`
  position: relative;
  .ReactInputVerificationCode__item {
    width: 60px;
    height: 80px;
    color: #262626;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 12px;
    margin-top: 20px;
  }
  .ReactInputVerificationCode__item:focus {
    border-bottom-color: #046cde;
  }
`;

const SignUpGetCode = () => {
  const navigation = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.leftBox}>
        <img className={styles.logincam} src={ASSETS.logincam} alt="" />
        <img className={styles.logo} src={ASSETS.logo} alt="" />
      </div>
      <div className={styles.rightBox}>
        <div className={styles.imgBox}>
          <DropdownTr style={{ position: "absolute", right: "25px", top: "25px" }} />
          <img src={ASSETS.logintab} alt="" />
        </div>
        <div className={styles.form}>
          <TextView title="Kodni kiriting" />
          <CustomStylesContainer>
            <ReactInputVerificationCode length={5} placeholder="" />
          </CustomStylesContainer>
        </div>
        <div className={styles.bottomBox}>
          <ButtonView
            clickMe={() => navigation("/")}
            icon={<LeftIcons />}
            title="Ro‘yhatdan o‘tish"
            buttonSize={"large"}
            buttonType={"primary"}
          />
        </div>
      </div>
    </div>
  );
};

export default SignUpGetCode;
