import React from "react";
import { LogOutIcons } from "../../assets/icons/IconsSvg";
import styles from "./LogOut.module.css";
type propsType = {
  logOut?: any;
  setLogOut?: any;
  setLogOutModal?: any;
};
const LogOut = (props: propsType) => {
  return (
    <div
      className={styles.container}
      style={{ bottom: props.logOut ? "0%" : "-100%" }}
    >
      <div
        className={styles.bottom}
        onClick={() => props.setLogOut(false)}
      ></div>
      <div
        className={styles.logout_box}
        onClick={() => props.setLogOutModal((a: any) => !a)}
      >
        <div className={styles.iconBox}>
          <LogOutIcons />
        </div>
        <p className={styles.logout_title}>Tizimda chiqish</p>
      </div>
    </div>
  );
};

export default LogOut;
