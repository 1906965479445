import React from "react";
import styles from "./CommentsList.module.css";
const CommentsList = () => {
  return (
    <div className={styles.container}>
      <div style={{ flex: 0.5 }} className={styles.item}>
        <p>2022.18.09</p>
        <p>Dush, 12:09</p>
      </div>

      <div style={{ flex: 1 }} className={styles.item}>
        <p>Toshkent shahardan chiqib ketdi</p>
        <p></p>
      </div>
      <div style={{ flex: 0.7 }} className={styles.item}>
        <h4>Kuryer</h4>
      </div>
    </div>
  );
};

export default CommentsList;
