import { useState } from "react";
import {
  AddIcons,
  CancelIcon,
  CloseIcons,
} from "../../../assets/icons/IconsSvg";

import ButtonView from "../../ButtonView/view";
import HeaderView from "../../Header/view";
import styles from "./EnterAddMailModal.module.css";
interface Props {
  onOpen?: boolean;
  onCancel?: () => void;
  show?: any;
  setNoteOpen?: any;
}

const EnterAddMailModal = ({ onOpen, onCancel }: Props) => {
  return (
    <div className={`${styles.container} ${onOpen && styles.active}`}>
      <div className={styles.bottom} onClick={onCancel}></div>
      <div className={styles.newBranchBox}>
        <HeaderView
          title="Yangi pochta qo‘shish"
          onCancel={onCancel}
          icon={<CancelIcon />}
        />
        <div className={styles.lists}>
          <p>Izoh yozing</p>
          <div className={styles.input_box}>
            <div className={styles.input}>
              <input placeholder="Misol uchun: Pochta admin tomonidan aktivlashtirildi" />
            </div>
          </div>
        </div>

        <div className={styles.footer}>
          <ButtonView
            buttonSize="small"
            buttonType="primary"
            title="Qo‘shish"
            icon={<AddIcons />}
          />
          <ButtonView
            buttonSize="small"
            buttonType="primary"
            title="Tasdiqlash"
            icon={<CloseIcons />}
            style={{
              marginLeft: 20,
              backgroundColor: "#ffffff",
              color: "red ",
              border: "1px solid #ED712B",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default EnterAddMailModal;
