import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { API_REQUEST_TIMEOUT, API_URL } from './api.constants';
import {
	LoginPayload,
	LoginResponse,
	SignUpPayload,
	SignUpResponse,
} from './requests';
import tokens from './tokens';

class Api {
	private readonly axios: AxiosInstance;

	constructor() {
		this.axios = axios.create({
			baseURL: API_URL,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
			timeout: API_REQUEST_TIMEOUT,
		});

		this.axios.interceptors.response.use(
			(response: AxiosResponse) => response,
			(error) => {
				if (error) {
				}
				return Promise.reject(error);
			}
		);

		this.axios.interceptors.request.use(
			(config: any) => {
				const token = tokens.getAccessToken();
				if (token) {
					config.headers = {
						...config.headers,
						Authorization: `Bearer ${token}`,
					};
				}
				return config;
			},
			(error) => {
				return Promise.reject(error);
			}
		);
	}

	// constructor() {
	//   this.axios = axios.create({
	//     baseURL: API_URL,
	//     headers: {
	//       'Content-Type': 'application/json',
	//       'Accept': 'application/json',
	//     },
	//   });
	// }

	public setAccessToken = (accessToken: string) => {
		this.axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
	};

	public clearAccessToken = () => {
		delete this.axios.defaults.headers.common.Authorization;
	};

	public hasAuthorizationHeader = () =>
		!!this.axios.defaults.headers.common.Authorization;

	// Sign Up

	public signUp = (payload: SignUpPayload) =>
		this.axios.post<any, SignUpResponse>(
			'/api/auth/sanctum/register',
			payload
		);

	// Sign In

	public login = (payload: LoginPayload) =>
		this.axios.post<LoginResponse>('/api/auth/sanctum/login', payload);

	// All Users Storage

	public getAllUsersStorages = () => this.axios.get('/api/storage/all');
}

const api = new Api();

export default api;
