import React, { useState } from "react";
import { AddIcons } from "../../../assets/icons/IconsSvg";
import ButtonView from "../../ButtonView/view";
import ListItem from "../../ListItem/ListItem";
import styles from "./NavbarEnter.module.css";
type PropsType = {
  data?: any;
  title?: string;
  info?: any;
  button_title?: any;
  data2?: any;
};

const NavbarEnter = (props: PropsType) => {
  const [clickMe, setClickMe] = useState();
  const [clickMe2, setClickMe2] = useState(props.data2[0].info);

  return (
    <div className={styles.navbar}>
      <div className={styles.left_container}>
        <div className={styles.title_box}>
          <h1>{props.title}</h1>
          {props.info ? <p>{props.info}</p> : null}
        </div>
        {props.data ? (
          <div className={styles.list_box_div}>
            <ul className={styles.list_box}>
              {props.data?.map(
                (item: {
                  title: string | undefined;
                  id: number | undefined;
                  color: any;
                }, index: number) => {
                  return (
                    <ListItem
                      key={index}
                      title={item.title}
                      number={item.id}
                      setClickMe={setClickMe}
                      clickMe={clickMe}
                      color={item.color}
                    />
                  );
                }
              )}
            </ul>
          </div>
        ) : null}
      </div>
      <div className={styles.right_container}>
        {props.data2 ? (
          <div className={styles.list_right}>
            {props.data2?.map((item: any) => {
              return (
                <p
                  key={item.id}
                  onClick={() => setClickMe2(item.info)}
                  style={{
                    color: clickMe2 === item.info ? "#ED712B" : " ",
                  }}
                >
                  {item.info}
                </p>
              );
            })}
          </div>
        ) : null}

        {/* <ButtonView
          buttonSize={"large"}
          buttonType={"primary"}
          style={{ background: "#3FDA7D", border: "none" }}
          title="Pochta qo‘shish"
          icon={<AddIcons />}
        /> */}
      </div>
    </div>
  );
};

export default NavbarEnter;
