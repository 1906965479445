import { createContext } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';
import {
	AppNotification,
	AppNotifications,
} from '../../types/Notification.types';

const ADD_NOTIFICATION_DELAY = 10;

export default class NotificationStore {
	notifications: AppNotifications = [];

	constructor() {
		makeAutoObservable(this);
	}
}
