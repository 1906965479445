import React from "react";
import { LogOutIcons } from "../../../assets/icons/IconsSvg";
import { COLORS } from "../../../constants/colors";
import ButtonView from "../../ButtonView/view";
import styles from "./LogOutModal.module.css";
type PropsType = {
  logOutModal?: any;
  setLogOutModal?: any;
  icon?: any;
};
const LogOutModal = (props: PropsType) => {
  return (
    <div
      className={styles.container}
      style={{ display: props.logOutModal ? " flex" : "none" }}
    >
      <div
        className={styles.bottom}
        onClick={() => props.setLogOutModal(false)}
      ></div>
      <div className={styles.modal_box}>
        <div className={styles.icon_box}>
          <div>
            <LogOutIcons width={"55"} height={"50"} />
          </div>
        </div>
        <div className={styles.info}>
          <p>Tizimdan rostdan ham chiqishni istaysizmi ?</p>
        </div>
        <div className={styles.button}>
          <ButtonView
            buttonSize={"small"}
            buttonType={"primary"}
            style={{
              backgroundColor: COLORS.wh1000,
              border: "1px solid #6A6A6A",
              color: "#6A6A6A",
              marginRight: "25px",
            }}
            title="Ha, chiqish"
          />
          <ButtonView
            buttonSize={"small"}
            buttonType={"primary"}
            clickMe={() => props.setLogOutModal()}
            style={{
              backgroundColor: "#EE1E50",
              border: " 1px solid #EE1E50",
              color: COLORS.wh1000,
              marginLeft: "25px",
            }}
            title="Yo‘q, ortga"
          />
        </div>
      </div>
    </div>
  );
};

export default LogOutModal;
